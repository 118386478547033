import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig =(isAuthenticated)=>  {
  const links = [
      {
        title: 'Home',
        path: '/',
        icon: icon('ic_home'),
      },
    
      {
        title: 'product',
        path: '/products',
        icon: icon('ic_cart'),
      },
      
      
    ];
  if(isAuthenticated){
    links.push({
      title: 'Logout',
      path: '/auth/account/logout',
      icon: icon('ic_account-logout'),
    },)
  }else{
    links.push({
      title: 'Login',
      path: '/auth/account',
      icon: icon('ic_lock'),
    },)
  }
  return links;
}

export default navConfig;
