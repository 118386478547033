// import Layout from "../core/AdminLayout";
import { Container, Grid, Paper, Toolbar } from "@mui/material";

import { useEffect } from "react";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";
import AppCurrentSubject from "./overview/app-current-subject";
import AppConversionRates from "./overview/app-conversion-rates";
import AppCurrentVisits from "./overview/app-current-visits";
import AppWebsiteVisits from "./overview/app-website-visits";
import AppWidgetSummary from "./overview/app-widget-summary";
const AdminDashboard = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <AdminBaseLayout>
      <Container component={Paper} elevation={3} className="small-containe">
        <Toolbar />
        <h3 className="title">Dashboard</h3>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <AppWidgetSummary
              title="Weekly Sales"
              total={714000}
              color="success"
              icon={
                <img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <AppWidgetSummary
              title="New Users"
              total={1352831}
              color="info"
              icon={
                <img alt="icon" src="/assets/icons/glass/ic_glass_users.png" />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <AppWidgetSummary
              title="Item Orders"
              total={1723315}
              color="warning"
              icon={
                <img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <AppWidgetSummary
              title="Bug Reports"
              total={234}
              color="error"
              icon={
                <img
                  alt="icon"
                  src="/assets/icons/glass/ic_glass_message.png"
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chart={{
                labels: [
                  "01/01/2003",
                  "02/01/2003",
                  "03/01/2003",
                  "04/01/2003",
                  "05/01/2003",
                  "06/01/2003",
                  "07/01/2003",
                  "08/01/2003",
                  "09/01/2003",
                  "10/01/2003",
                  "11/01/2003",
                ],
                series: [
                  {
                    name: "Team A",
                    type: "column",
                    fill: "solid",
                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                  },
                  {
                    name: "Team B",
                    type: "area",
                    fill: "gradient",
                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                  },
                  {
                    name: "Team C",
                    type: "line",
                    fill: "solid",
                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                  },
                ],
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chart={{
                series: [
                  { label: "America", value: 4344 },
                  { label: "Asia", value: 5435 },
                  { label: "Europe", value: 1443 },
                  { label: "Africa", value: 4443 },
                ],
              }}
            />
          </Grid>
        </Grid>
        <Toolbar />
      </Container>
    </AdminBaseLayout>
  );
};
export default AdminDashboard;
