import getCommonOptions from "src/helpers/axios/getCommonOptions";
import { LOCAL_API } from "../config";
import axios from "axios";

export const getBraintreeClientToken = (userId) => {
  return axios.get(
    `${LOCAL_API}/braintree/get-token/${userId}`,
    getCommonOptions()
  );
};

export const processPayment = (userId, paymentData) => {
  return axios.post(
    `${LOCAL_API}/braintree/payment/${userId}`,
    paymentData,
    getCommonOptions()
  );
};
