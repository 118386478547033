import { useContext, useEffect, useRef, useState } from "react";
// import { getProducts } from "./apiCore";
import $ from "jquery";
import { getProductInventory } from "src/api/productInventoryAPI";
import "src/assets/css/lightslider.css";
import "src/assets/js/lightslider";
import Card from "src/components/reusable/Card";

import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "src/contexts/AuthContextProvider";

const Home = () => {
  const [productsBySell, setProductsBySell] = useState([]);
  const [productsByArrival, setProductsByArrival] = useState([]);

  //Get references to featured and best seller divs to attach lightSlider
  //framework on them.
  const featuredSliderRef = useRef(); //
  const bestSellerSliderRef = useRef();

  const { user } = useContext(AuthContext);
  if (user) {
    const acq = new Date(user?.iat);
    const exp = new Date(user?.exp);
    console.log("iat: ", acq);
    console.log("Expiry: ", exp);
  }
  console.log("User is : ", user);

  const loadProductsBySell = async () => {
    try {
      const res = await getProductInventory(1, 10);
      setProductsBySell(res.data.productInventory);
    } catch (error) {
      console.log(error?.message);
    }
  };
  const loadProductsByArrival = async () => {
    try {
      const res = await getProductInventory(1, 10);
      setProductsByArrival(res.data.productInventory);
    } catch (error) {
      //console.log(error?.message);
    }
  };

  useEffect(() => {
    loadProductsByArrival();
    loadProductsBySell();
    $(featuredSliderRef.current).lightSlider({
      item: 4,
      auto: true,
      loop: true,
      pauseOnHover: true,
      slideMove: 1,
      easing: "cubic-bezier(0.25, 0, 0.25, 1)",
      speed: 600,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            item: 3,
            slideMove: 1,
            slideMargin: 6,
          },
        },
        {
          breakpoint: 640,
          settings: {
            item: 2,
            slideMove: 1,
            slideMargin: 6,
          },
        },
        {
          breakpoint: 480,
          settings: {
            item: 1,
            slideMove: 1,
          },
        },
      ],
      onSliderLoad: function () {
        $(window).resize();
      },
      onAfterSlide: function () {
        $(window).resize();
      },
    });
    $(bestSellerSliderRef.current).lightSlider({
      item: 4,
      auto: true,
      loop: false,
      pauseOnHover: true,
      slideMove: 1,
      easing: "cubic-bezier(0.25, 0, 0.25, 1)",
      speed: 600,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            item: 3,
            slideMove: 1,
            slideMargin: 6,
          },
        },
        {
          breakpoint: 480,
          settings: {
            item: 1,
            slideMove: 1,
          },
        },
      ],
    });
  }, []);
  const showHero = () => {
    return (
      <Box
        sx={{
          pl: 2,
        }}
        className="row"
      >
        <div className="col-2">
          <h1>
            Give your workout <br />
            New Style
          </h1>
          <p>
            Success isn't alsways about greatness. It's about consistency.
            Consistent <br />
            hard work gains success. Greatness will come.
          </p>
          <Link to="#" className="btn">
            Explore now &#8594;
          </Link>
        </div>
        <div className="col-2 banner">
          <img src={process.env.PUBLIC_URL + "/images/banner.webp"} alt="" />
          &nbsp;
        </div>
      </Box>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      {showHero()}
      <Box className="small-container">
        <h2 className="title">Featured Products</h2>
        <div className="row-slider">
          <ul ref={featuredSliderRef}>
            {productsByArrival &&
              productsByArrival.length &&
              productsByArrival?.map((product, i) => (
                <li key={i}>
                  <div className="col-4">
                    <Card productInventory={product} />
                  </div>
                </li>
              ))}
          </ul>
        </div>

        <h2 className="title">Latest Products </h2>
        <div className="row-slider">
          <ul ref={bestSellerSliderRef}>
            {productsBySell &&
              productsBySell.length &&
              productsBySell?.map((product, i) => (
                <li key={i}>
                  <div className="col-4">
                    <Card productInventory={product} />
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </Box>
    </Box>
  );
};

export default Home;
