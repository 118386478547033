let environment = process.env.REACT_APP_ENVIRONMENT;
let api_url = "";
if (!environment) environment = "development";
switch (environment) {
  case "development":
    api_url = process.env.REACT_APP_API_URL_LOCAL;
    break;
  case "development_vps":
    api_url = process.env.REACT_APP_API_URL_VPS;
    break;
  case "production":
    api_url = process.env.REACT_APP_API_URL_PRODUCTION;
    break;

  default:
    api_url = "http:localhost:5000";
    break;
}
export const API = api_url;
export const LOCAL_API = api_url;
export const ENVIRONMENT = environment;
