import { Box, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import { FileUploader } from "react-drag-drop-files";

import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Brand name is required")
    .max(100, "Max length is 100"),
});

const BrandForm = ({ brand, setBrand, handleSubmit }) => {
  const fileTypes = ["JPG", "PNG", "GIF", "WEBP"];
  let { name, images, formData } = brand;

  const handleChange = (fieldName) => (event) => {
    if (!formData) formData = new FormData();
    let value =
      fieldName === "file" ? event.target.files[0] : event.target.value;
    setBrand({
      ...brand,
      [fieldName]: value,
    });
    formData.set(fieldName, value);
  };

  const handleImageUpload = (file) => {
    if (!formData) formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      images.push(file[i]);
      formData.append("images", file[i]);
    }
    setBrand({
      ...brand,
      images: images,
    });
  };

  return (
    <Box className="mgmt-for">
      <Formik
        onSubmit={handleSubmit}
        initialValues={brand}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form
              method="post"
              className="brand-for"
              onSubmit={formik.handleSubmit}
              encType="multipart/form-data"
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    // {...formik.getFieldProps("name")}
                    sx={{
                      mb: 2,
                      mt: 2,
                    }}
                    value={name}
                    onChange={handleChange("name")}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
              </Grid>
              <div className="form-group">
                <label htmlFor="images"></label>

                <FileUploader
                  handleChange={handleImageUpload}
                  label="Add Brand images"
                  name="images"
                  types={fileTypes}
                  multiple={true}
                  classes="drop_zone drop_area"
                />
              </div>
              <input type="submit" className="btn" value="Add" />
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
export default BrandForm;
