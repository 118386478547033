import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  //createCategory,
  getCategories,
} from "src/api/categoriesAPI";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";

const Categories = (props) => {
  //State variables
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({
    name: "",
    slug: "",
    images: [{}],
    parentId: "",
    formData: new FormData(),
  });

  //Destructure user and toker from localStorage

  //Destructure formData from state variable category
  let { formData } = category;
  if (!formData) formData = new FormData();
  //References
  const catListRef = useRef(null);

  //useNavigate hook
  const navigate = useNavigate();

  const categoryClicked = (cat) => {
    setCategory(cat);
  };
  const init = useCallback(async () => {
    try {
      const res = await getCategories();
      res.status === 200 ? setCategories(res.data) : setCategories([]);
    } catch (error) {}
  }, []);
  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <AdminBaseLayout>
      <Box component={Paper} elevation={3} className="small-container">
        <h2 className="title">Categories</h2>

        <Box
          elevation={1}
          className="mgmt-container"
          sx={{
            padding: 2,
          }}
        >
          <Box className="mgmt-list-wrapper" ref={catListRef}>
            <div className="table">
              <div className="table-header">
                <p>Categories</p>
                <div className="th-actions">
                  <div className="search-bar">
                    <input type="text" placeholder="Category" id="" />
                    <span>
                      <i className="bx bx-search"></i>
                    </span>
                  </div>

                  <button
                    className="add-new"
                    onClick={() => navigate("/admin/category")}
                  >
                    {" "}
                    + Add New
                  </button>
                </div>
              </div>
              <Box className="table-section">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Slug</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categories &&
                      categories.map((cat, idx) => {
                        console.log(cat);
                        return (
                          <TableRow
                            onClick={() => categoryClicked(cat)}
                            key={idx}
                          >
                            <TableCell data-source={"#"}>{++idx}</TableCell>
                            <TableCell data-source={"Name"}>
                              {cat.name}
                            </TableCell>
                            <TableCell data-source={"Parent"}>
                              {cat.slug}
                            </TableCell>

                            <TableCell data-source={"Action"}>
                              <div>
                                <button onClick={() => categoryClicked(cat)}>
                                  <i className="bx bx-edit"></i>
                                </button>
                                <button>
                                  <i className="bx bx-trash"></i>
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            </div>
          </Box>
          {/* <Box className="mgmt-form-wrapper" ref={catFormRef}>
          <CategoryForm
            category={category}
            setCategory={setCategory}
            serverResponse={serverResponse}
            setServerResponse={setServerResponse}
            handleSubmit={handleSubmit}
            action="update"
            buttonName="Update"
          >
            <div>
              <a href="/#" onClick={(e) => backToCategoryClicked(e)}>
                Back to categories
              </a>
            </div>
          </CategoryForm>
        </Box> */}
        </Box>
      </Box>
    </AdminBaseLayout>
  );
};

export default Categories;
