import { Box, Paper } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProductTypes } from "src/api/productTypeAPI";
import "../style.css";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";
const ProductTypes = (props) => {
  const [productTypes, setProductTypes] = useState([]);
  const navigate = useNavigate();
  const loadProductType = async () => {
    const res = await getProductTypes();
    if (res.status === 200) setProductTypes(res.data);
  };
  useEffect(() => {
    loadProductType();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <AdminBaseLayout>
      <Box className="small-container" component={Paper} elevation={3}>
        <Box className="row">
          <h2 className="title">Product Types</h2>
          <Box className="mgmt-container">
            <Box className="table">
              <Box className="table-header">
                <p>{productTypes.length} product types</p>
                <div className="th-actions">
                  <div className="search-bar">
                    <input type="text" placeholder="Product type" />
                    <span>
                      <i className="bx bx-search"></i>
                    </span>
                  </div>

                  <button
                    className="add-new"
                    onClick={() => navigate("/admin/product-type")}
                  >
                    + Add New
                  </button>
                </div>
              </Box>
              <div className="table-section">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Created at:</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productTypes &&
                      productTypes.map((prodType, idx) => (
                        <tr key={idx}>
                          <td data-source="Name"> {prodType.name}</td>
                          <td data-source="Created At">
                            {" "}
                            {moment(prodType.createdAt).format("MM/DD/YYYY")}
                          </td>
                          <td data-source="Actions">
                            <button>
                              <i className="bx bx-edit"></i>
                            </button>
                            <button>
                              <i className="bx bx-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </AdminBaseLayout>
  );
};

export default ProductTypes;
