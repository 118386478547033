import { useCallback, useState, useContext } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import formatHttpApiError from "src/helpers/formatHttpApiError";

import { AuthContext } from "src/contexts/AuthContextProvider";
import getCommonOptions from "src/helpers/axios/getCommonOptions";

import { LOCAL_API } from "src/config";

export default function useRequestAuth() {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const [logoutPending, setLogoutPending] = useState(false);
  const { setIsAuthenticated } = useContext(AuthContext);

  const handleRequestError = useCallback(
    (err) => {
      const formattedError = formatHttpApiError(err);
      setError(formattedError);
      enqueueSnackbar(formattedError, { variant: "error" });
      setLoading(false);
    },
    [enqueueSnackbar, setLoading, setError]
  );
  const changePassword = useCallback(
    ({ current_password, new_password, re_new_password }, successCallback) => {
      setLoading(true);
      axios
        .post(
          `${LOCAL_API}/auth/users/set_password/`,
          {
            current_password,
            new_password,
            re_new_password,
          },
          getCommonOptions()
        )
        .then((data) => {
          enqueueSnackbar("Password changed successfully", {
            variant: "success",
          });
          setLoading(false);
          if (successCallback) {
            console.log();
            successCallback();
          }
        })
        .catch(handleRequestError);
    },
    [enqueueSnackbar, setLoading, handleRequestError]
  );

  const register = useCallback(
    ({ firstname, lastname, mobile, email, password }, successCallback) => {
      setLoading(true);
      axios
        .post(`${LOCAL_API}/auth/users/`, {
          firstname,
          lastname,
          mobile,
          email,
          password,
        })
        .then((data) => {
          enqueueSnackbar(
            "Sign up is successful, you can now sign in with your credentials",
            {
              variant: "success",
            }
          );
          setLoading(false);
          if (successCallback) {
            console.log();
            successCallback();
          }
        })
        .catch(handleRequestError);
    },
    [enqueueSnackbar, setLoading, handleRequestError]
  );

  const login = useCallback(
    ({ email, password }) => {
      setLoading(true);
      axios
        .post(`${LOCAL_API}/auth/token/login`, {
          email,
          password,
        })
        .then((res) => {
          enqueueSnackbar("You have successfully logged in.", {
            variant: "success",
          });
          console.log("response from login: ", res.data);
          const { token } = res.data;
          localStorage.setItem("jwt", token);
          setIsAuthenticated(true);
          setLoading(false);
        })
        .catch(handleRequestError);
    },
    [enqueueSnackbar, setLoading, handleRequestError, setIsAuthenticated]
  );
  const logout = useCallback(() => {
    setLogoutPending(true);
    axios
      .get(`${LOCAL_API}/auth/token/logout`, {}, getCommonOptions())
      .then(() => {
        setLogoutPending(false);
        localStorage.removeItem("jwt");
        setIsAuthenticated(false);
        enqueueSnackbar("You have successfully logged out.");
      })
      .catch((err) => {
        setLogoutPending(false);
        handleRequestError(err);
      });
  }, [
    setLogoutPending,
    setIsAuthenticated,
    handleRequestError,
    enqueueSnackbar,
  ]);

  return {
    register,
    login,
    logout,
    changePassword,
    logoutPending,
    loading,
    error,
  };
}
