import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "src/contexts/AuthContextProvider";

export default function AdminRoute() {
  const { isAuthenticated, user } = React.useContext(AuthContext);

  if (isAuthenticated === null) {
    return <div>Loading.....</div>;
  }

  if (isAuthenticated === true && user?.user?.role === 1) {
    return <Outlet />;
  }
  return <Navigate to="/auth/account" />;
}
