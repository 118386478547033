import getCommonOptions from "src/helpers/axios/getCommonOptions";
import { LOCAL_API } from "../config";
import axios from "axios";

export const createOrder = (userId, orderData) => {
  return axios.post(
    `${LOCAL_API}/order/create/${userId}`,
    { order: orderData },
    getCommonOptions()
  );
};
