import axios from "axios";
import { LOCAL_API } from "../config";
import getCommonOptions from "src/helpers/axios/getCommonOptions";
export const addProductsAttributes = (userId, token, attributes) => {
  return axios.post(
    `${LOCAL_API}/products/attributes/${userId}`,
    attributes,
    getCommonOptions()
  );
  // return fetch(`${LOCAL_API}/products/attributes/${userId}`, {
  //   method: "POST",
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   },
  //   data: attributes,
  // })
  //   .then((data) => {
  //     return data.json();
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
};

export const getProductsAttributes = () => {
  return axios.get(`${LOCAL_API}/products/attributes`);
};

export const getAttributesForProductInventory = (productInventoryId) => {
  return axios.get(`${LOCAL_API}/product-attributes/${productInventoryId}`);
};
