import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";
import { deleteProduct, getProductInventory } from "../../../api/apiAdmin";
import { API } from "../../../config";
import { isAuthenticated } from "../../../services/auth";
// import AdminNavigation from "../../user/AdminNavigation";
const ManageProductInventory = (props) => {
  const [productInventory, setProductInventory] = useState([]);

  const { user, token } = isAuthenticated();

  const loadProductInventory = () => {
    getProductInventory().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setProductInventory(data);
        console.log("returned products ", productInventory);
      }
    });
  };

  const destroyProduct = (productId) => {
    deleteProduct(productId, user._id, token).then((response) => {
      if (response.error) {
        console.log(response.error);
      } else {
        console.log("Product deleted");
        loadProductInventory();
      }
    });
  };

  // const showProducts = () =>
  //   //console.log(products);
  //   productInventory && (
  //     <table className="table">
  //       <thead>
  //         <tr>
  //           <th scope="col">Product</th>
  //           <th scope="col">description</th>
  //           <th scope="col">sold</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {productInventory &&
  //           productInventory.map((product, idx) => {
  //             console.log(product.name);
  //             return (
  //               <tr key={idx}>
  //                 <td>
  //                   <div className="row">
  //                     <div className="col">
  //                       <img
  //                         src={`${API}/product/photo/${product.id}`}
  //                         alt=""
  //                       />
  //                     </div>
  //                     <div className="col">
  //                       <div className="">
  //                         {product.name}
  //                         <p>{`Price: ${product.price}`}</p>
  //                         <p> {`quantity: ${product.quantity}`}</p>
  //                         <p>{`Sold: ${product.sold}`}</p>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </td>

  //                 <td>{product.description}</td>
  //                 {/* <td>{product.name}</td>
  //                 <td>{product.price}</td>
  //                 <td>{product.quantity}</td>
  //                 <td>{product.sold}</td> */}
  //                 <td>
  //                   <Link to={"product/update/" + product.id}>Update</Link>
  //                 </td>
  //               </tr>
  //             );
  //           })}
  //       </tbody>
  //     </table>
  //   );

  useEffect(() => {
    loadProductInventory();
  }, []);

  return (
    <AdminBaseLayout {...props} title="Manage Products" className="container">
      <div className="small-container">
        {/* <div className="row">
          <AdminNavigation props={props} />
          <div className="col-2">
            <div class="table-container" role="table" aria-label="Destinations">
              <div class="flex-table header" role="rowgroup">
                <div class="flex-row first" role="columnheader">
                  Country
                </div>
                <div class="flex-row" role="columnheader">
                  Events
                </div>
                <div class="flex-row" role="columnheader">
                  Time
                </div>
                <div class="flex-row" role="columnheader">
                  Fees
                </div>
              </div>
              {products &&
                products.map((product, idx) => {
                  return (
                    <div class="flex-table row" role="rowgroup">
                      <div class="flex-row first" role="cell">
                        <img
                          src={`${API}/product/photo/${product._id}`}
                          alt=""
                          style={{ width: "150px", height: "150px" }}
                        />
                        {""}
                        <br />
                        {product.name}
                      </div>
                      <div class="flex-row" role="cell">
                        {product.description}{" "}
                      </div>
                      <div class="flex-row" role="cell">
                        {product.created_at}
                      </div>
                      <div class="flex-row" role="cell">
                        KSh {product.price}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-2">
            <h4>Total Products: {productInventory.length}</h4>
            <hr />
            <ul className="list-group">
              {productInventory.map((p, i) => (
                <li
                  key={i}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <strong>{p.sku}</strong>
                  <Link to={`/admin/product-inventory/update/${p.id}`}>
                    <span className="badge badge-warning badge-pill">
                      Update
                    </span>
                  </Link>
                  <span
                    className="badge badge-danger badge-pill"
                    onClick={() => destroyProduct(p.id)}
                  >
                    Delete
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </AdminBaseLayout>
  );
};

export default ManageProductInventory;
