import { useContext, useEffect, useRef, useState } from "react";
import { getProductsAttributes } from "src/api/apiAdmin";
import { getBrands } from "src/api/brandAPI";
import { createProduct, createProductWithInventory } from "src/api/productAPI";
import { getProductTypes } from "src/api/productTypeAPI";
import { validateProductForm } from "src/helpers/FormValidator";
import { isAuthenticated } from "src/services/auth";

import { Paper } from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ProductInventoryForm from "../ProductInventory/ProductInventoryForm";
import ProductAttributesForm from "./ProductAttributesForm";
import ProductForm from "./ProductForm";

import { AuthContext } from "src/contexts/AuthContextProvider";
import { enqueueSnackbar } from "notistack";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";

const ProductDetails = (props) => {
  //Destructure user and token from localStorage
  const { user } = useContext(AuthContext).user;

  //This state variable will hold serverResponse messages and loading state.
  const [pageVars, setPageVars] = useState({
    loading: false,
    status: { status: "", message: "" },
  });
  let { loading, status } = pageVars;

  //Product form attributes
  const [product, setProduct] = useState({
    name: "",
    slug: "",
    description: "",
    chosenCategories: [],
  });

  const [categories, setCategories] = useState([]);

  //This will show set the server response message which will be displayed when the form
  // is being submited, or when moving from one form to the next. Default is set to status
  const [responseMessage, setResponseMessage] = useState(status);

  // This state variable will be used to hold product inventory information.
  // These are actual product inventories with viatiations like, color, measurements, etc.
  const [productInventory, setProductInventory] = useState({
    sku: "",
    upc: "",
    productType: "",
    product: {},
    media: [],
    rrPrice: "",
    storePrice: "",
    salePrice: "",
    inStock: "",
    brand: "",
    loading: false,
    status: { status: "", message: "" },
    formData: new FormData(),
  });
  //File types accepted as product images.
  const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

  //Files array to hold the uploaded images
  const [files, setFiles] = useState([]);

  // This state variable holds attributes of the selected product type
  const [productAttributes, setProductAttributes] = useState([]);

  //This variable was used in the previous implementation to select product for product inventory
  const [products, setProducts] = useState([{}]);

  //This state variable stores the categories the product belongs to.
  const [productTypes, setProductTypes] = useState([{}]);

  // This state variable holds the brands that exists so user can choose which
  // brand the product belongs to.
  const [brands, setBrands] = useState([]);

  //Construct a reference the three form we will be using for product input
  //which are in different files. I have used React.forwardRef on those componentes that's
  //why I can reference them here. Since there are three I initialize current to an array.
  const tabsRef = useRef([]);
  tabsRef.current = [];

  const [currentTab, setCurrentTab] = useState(0);
  // let currentTab = 0;

  // references to the next and previous buttons which moves from one form to the next
  const prevBtn = useRef();
  const nextBtn = useRef();

  //Construct a reference the three form we will be using for product input
  //which are in different files. I have used React.forwardRef on those components that's
  //why I can reference them here. Since there are three I initialize current to an array.
  const steps = useRef([]);
  steps.current = [];

  //Create a function that will be passed to the ref prop of each step span element which will
  //indicate which form step is being shown.
  const addStepRef = (el) => {
    //I am checking whether the element that was passed in not in the steps.current array
    //and add it if it's not. I am able to run the .includes method of the current object which
    //is only available using refs.
    if (el && !steps.current.includes(el)) {
      steps.current.push(el);
    }
  };

  //Create a function that will be passed to the ref prop of each form for product input.
  const addTabRef = (el) => {
    //I am checking whether the element that was passed in not in the tabsRef.current array
    //and add it if it's not. I am able to run the .includes method of the current object which
    //is only available using refs.
    if (el && !tabsRef.current.includes(el)) {
      tabsRef.current.push(el);
    }
  };

  // This function displays the tab selected and the selected tab is passed as an argument.
  // By default, all tabs are hidden.
  const showTab = (n) => {
    console.log("Tab being displayed: ", n);
    // This function will display the specified tab of the form ...
    if (tabsRef.current[n]) tabsRef.current[n].style.display = "block";

    // ... and fix the Previous/Next buttons:
    if (n === 0) {
      prevBtn.current.style.display = "none";
    } else {
      prevBtn.current.style.display = "inline";
    }
    if (n === tabsRef.current.length - 1) {
      nextBtn.current.innerHTML = "Finish";
    } else {
      nextBtn.current.innerHTML = "Next";
    }
    // ... and run a function that displays the correct step indicator:
    fixStepIndicator(n);
  };

  const nextPrev = async (n) => {
    // This function will figure out which tab to display
    // Exit the function if any field in the current tab is invalid:
    if (n === 1 && !validateForm()) return false;

    // if the form is correctly filled, post it and go to the next form.
    let formToSubmit = tabsRef.current[currentTab].querySelector("form");
    switch (formToSubmit.id) {
      case "productForm":
        //submitAddProductForm();
        console.log("addProductForm ");
        break;
      case "productInventoryForm":
        console.log("Product inventory form ");
        break;
      case "productAttributesForm":
        console.log("add-product-inventory ");
        break;
      default:
        console.log("Id's did not match in form tabs");
    }

    // Hide the current tab:
    // This code is not working, and I don't know why I have added a clean-up
    // method in useEffect to do this job but I will investigate.
    tabsRef.current[currentTab].style.display = "none";

    // Increase or decrease the current tab by 1:
    // currentTab = currentTab + n;
    setCurrentTab(currentTab + n);

    // if you have reached the end of the form... :
    if (currentTab === tabsRef.current.length - 1) {
      //...the form gets submitted:
      const finalForm = productInventory.formData;

      finalForm.append("product", JSON.stringify(product));
      finalForm.append("productInventory", JSON.stringify(productInventory));
      finalForm.append("attributes", JSON.stringify(productAttributes));

      //Reset the form and go back to the first tab.
      if (n === 1 && validateForm()) {
        setCurrentTab(0);
        try {
          setPageVars({ ...pageVars, loading: true });
          const res = await createProductWithInventory(finalForm, user);
          console.log("After posting to add-with-inventory: ", res.data);
          enqueueSnackbar(res.data.message, { variant: "success" });
        } catch (error) {
          console.log("Error posting data: ", error.message);
          setPageVars({ ...pageVars, loading: false });
        }
        console.log("reseting tabs");
        setProductInventory({
          sku: "",
          upc: "",
          productType: "",
          product: {},
          media: [],
          rrPrice: "",
          storePrice: "",
          salePrice: "",
          inStock: "",
          brand: "",
          loading: false,
          status: { status: "", message: "" },
          formData: new FormData(),
        });
        setProduct({
          name: "",
          slug: "",
          description: "",
          chosenCategories: [],
        });
        setProductAttributes([]);
        setPageVars({ ...pageVars, loading: false });
      }

      return false;
    }
    // Otherwise, display the correct tab:
    showTab(currentTab);
  };

  const validateForm = () => {
    // This function deals with validation of the form fields
    var y,
      i,
      valid = true;
    y = tabsRef.current[currentTab].querySelectorAll("input");
    // A loop that checks every input field in the current tab:
    for (i = 0; i < y.length; i++) {
      // If a field is empty...
      if (y[i].value === "") {
        // add an "invalid" class to the field:
        y[i].className += " invalid";
        // and set the current valid status to false:
        valid = false;
      } else {
        y[i].className = "";
      }
    }
    // If the valid status is true, mark the step as finished and valid:
    if (valid) {
      steps.current[currentTab].className += " finish";
    }
    return valid; // return the valid status
  };

  const fixStepIndicator = (n) => {
    // This function removes the "active" class of all steps...
    for (let i = 0; i < steps.current.length; i++) {
      steps.current[i].className = steps.current[i].className.replace(
        " active",
        ""
      );
    }
    //... and adds the "active" class to the current step:
    console.log("Setting step indicator to: ", n);
    steps.current[n].className += " active";
  };

  const showLoading = () => (
    <div
      className="loading"
      style={{ display: loading ? "flex" : "none" }}
    ></div>
  );

  const submitAddProductForm = async () => {
    console.log("Product being submitted. ", product);
    const productForm = validateProductForm(product);
    setProduct({ ...product, loading: true });
    if (!productForm.formIsValid) {
      // status = { status: "error", message: JSON.stringify(productForm.errors) };
      loading = false;
      // setResponseMessage(status);
      enqueueSnackbar(JSON.stringify(productForm.errors), { variant: "error" });
      return;
    }

    try {
      const result = await createProduct(product, user);
      if (result.status === 200) {
        console.log("product added. response from server: ", result.data);
        status = result.data;
        setProduct({ ...product, ...result.data.data });
        let respMsg = "";
        if (typeof result.data === "object") {
          respMsg = result.data.message;
        } else {
          respMsg = result.data;
        }
        enqueueSnackbar(respMsg, { variant: "success" });
      } else if (result.status === 401) {
        //Should redirect to login page with this page as an argument so that
        //when the user logs back in he/she is directed back to this page.
        enqueueSnackbar("You are not authorized to perfom this action.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(result.status.statusText, { variant: "error" });
      }
    } catch (error) {
      if (error.status === 401) {
        //refresh token or redirect to login page
        // console.log("This is 401 catch and I can act here.");
      }

      enqueueSnackbar(error.message, { variant: "error" });
      // setResponseMessage(status);
    }
  };

  //This use effect is called only once when the component mounts.
  //I use it to fetch data needed by this component from server
  useEffect(() => {
    const init = async () => {
      try {
        const pTypeRes = await getProductTypes();
        const brandsRes = await getBrands();
        const pAttrRes = await getProductsAttributes();

        setProductTypes(pTypeRes?.data);
        setBrands(brandsRes?.data);
        setProductAttributes(pAttrRes?.data);
        console.log("Product Attributes: ", pAttrRes?.data);
      } catch (error) {
        console.log("Error getting required data from server: ", error);
      }
    };
    init();
  }, []);

  //This use effect is called whenever the currentTab value changes.
  //It hides previous form and re-renders display with the new form
  //pointed at by currentTab
  useEffect(() => {
    showTab(currentTab);
    //This clean up method hides the display of the previously displayed
    //form which is held by currentTab variable. I need to hide it here because it is
    //not hidding when nextPrev is called because display is not updating.
    return () => {
      tabsRef.current[currentTab].style.display = "none";
    };
  }, [currentTab]);

  return (
    <AdminBaseLayout>
      <Paper elevation={3} className="small-container">
        {showLoading()}
        <div className="row">
          <div className="col-2">
            <ProductForm
              product={product}
              setProduct={setProduct}
              categories={categories}
              setCategories={setCategories}
              serverResponse={responseMessage}
              setServerResponse={setResponseMessage}
              handleSubmit={submitAddProductForm}
              ref={addTabRef}
            />
            <ProductInventoryForm
              productInventory={productInventory}
              setProductInventory={setProductInventory}
              products={products}
              product={product}
              productTypes={productTypes}
              brands={brands}
              fileTypes={fileTypes}
              files={files}
              setFiles={setFiles}
              // handleSubmit={handleSubmit}
              buttonName="Add Product Inventory"
              ref={addTabRef}
            />
            <ProductAttributesForm
              productTypeId={productInventory.productType}
              attributes={productAttributes}
              setAttributes={setProductAttributes}
              ref={addTabRef}
            />

            <div style={{ overflow: "auto" }}>
              <div style={{ float: "right" }}>
                <button
                  type="button"
                  id="prevBtn"
                  ref={prevBtn}
                  onClick={() => nextPrev(-1)}
                >
                  Previous
                </button>
                <button
                  type="button"
                  id="nextBtn"
                  ref={nextBtn}
                  onClick={() => nextPrev(1)}
                >
                  Next
                </button>
              </div>
            </div>

            {/* <!-- Circles which indicates the steps of the form: --> */}
            <div
              className="circleIndicators"
              style={{ textAlign: "center", marginTop: "40px" }}
            >
              <span ref={addStepRef} className="step"></span>
              <span ref={addStepRef} className="step"></span>
              <span ref={addStepRef} className="step"></span>
            </div>
          </div>
        </div>
      </Paper>
    </AdminBaseLayout>
  );
};
export default ProductDetails;
