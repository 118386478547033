import { ContentState, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { getCategories } from "../../../api/categoriesAPI";
import { capitalize } from "../../../helpers/FormatString";
import * as yup from "yup";
import { Formik } from "formik";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";

const validationSchema = yup.object({
  name: yup.string().required("Name is required").max(100, "Max length is 100"),
  slug: yup.string().required("Slug is required").max(100, "Max length is 100"),
  mobile: yup
    .string()
    .required("Phone number is required")
    .max(20, "Max length is 20"),
  email: yup
    .string()
    .email("Please input a vaild emaila address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .max(100, "Max length is 100 characters"),
});
const ProductForm = React.forwardRef(
  ({ product, setProduct, categories, setCategories }, ref) => {
    //Destructure state variable product
    let { chosenCategories } = product;
    const [editorState, setEditorState] = useState(
      EditorState.createWithContent(ContentState.createFromText("something"))
    );
    const handleSubmit = () => {};
    //useEffect hook to fetch all the categories
    useEffect(() => {
      const init = async () => {
        try {
          const res = await getCategories();
          console.log("categories: ", res.data);
          setCategories(res.data);
        } catch (error) {
          setProduct({
            ...product,
            status: { status: "error", message: error },
          });
          console.log("error getting categories: ", error.message);
        }
      };
      init();
      console.log("Product object in request: ", product);
    }, []);

    const onEditorStateChange = (value) => {
      setEditorState(value);
      console.log(editorState.getCurrentContent().getPlainText("\u0001"));
      setProduct({
        ...product,
        description: editorState.getCurrentContent().getPlainText("\u0001"),
      });
    };
    //Set values in product state whenever a form field changes value
    const handleChange = (name) => (event) => {
      if (name === "category") {
        if (event.target.checked === true) {
          chosenCategories.push(event.target.value);
        } else if (event.target.checked === false) {
          chosenCategories.splice(
            chosenCategories.indexOf(event.target.value) - 1
          );
        }
      } else {
        setProduct({ ...product, [name]: event.target.value });
      }
    };

    return (
      <div className="tab" ref={ref}>
        <h2 className="title">Add Product</h2>
        <div className="mgmt-form">
          <Formik
            onSubmit={handleSubmit}
            initialValues={product}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {(formik) => (
              <form action="" id="register-form" onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      id="name"
                      label="Name"
                      value={product.name}
                      onChange={handleChange("name")}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      id="slug"
                      label="Slug"
                      value={product.slug}
                      onChange={handleChange("slug")}
                      error={formik.touched.slug && Boolean(formik.errors.slug)}
                      helperText={formik.touched.slug && formik.errors.slug}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup className="categories-checkboxes">
                      {categories &&
                        categories.map((category, idx) => (
                          <FormControlLabel
                            key={idx}
                            control={
                              <Checkbox
                                onChange={handleChange("category")}
                                value={category.id}
                              />
                            }
                            label={capitalize(category.name)}
                          />
                        ))}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form-group">
                      <label>Description</label>
                      <Editor
                        initialEditorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                      />
                    </div>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
);

export default ProductForm;
