import { ShoppingCart } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { LOCAL_API } from "src/config";
import {
  cartTotal,
  getCart,
  removeItem,
  updateItem,
} from "src/helpers/cartHelpers";
import { fCurrency } from "src/utils/format-number";
import Checkout from "../Checkout";

const Cart = () => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    //getCart returns an array of product inventory stored in cart
    setCart(getCart());
  }, []);

  const getCartTotal = (cart) => {
    return cart.reduce((total, currentValue) => {
      return total + currentValue.count * currentValue.storePrice;
    }, 0);
  };

  const handleQuantityChange = (event, productId) => {
    if (event.target.value >= 1) {
      updateItem(productId, event.target.value);
      setCart(getCart());
    }
  };
  const removeProduct = (productId) => (event) => {
    event.preventDefault();
    removeItem(productId);
    setCart(getCart());
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showCartItems = (items) => {
    return (
      <Box>
        {/* <!---------------------- cart items detail ------------------- --> */}
        <Paper elevation={3} sx={{ px: 2 }}>
          <Box>
            <h2 className="title">
              Shopping Cart <ShoppingCart color="inherit" size="small" />
            </h2>
          </Box>
          <Grid container spacing={3} sx={{ mb: 5, px: 4 }}>
            <Grid item xs={6}>
              <Typography
                component={"div"}
                variant="subtitle1"
                sx={{ fontWeight: 900 }}
              >
                Product
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                component={"div"}
                variant="subtitle1"
                sx={{ fontWeight: 900 }}
              >
                Quantity
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign={"right"}>
              <Typography
                component={"div"}
                variant="subtitle1"
                sx={{ fontWeight: 900 }}
              >
                Total
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems={"center"} sx={{ px: 2 }}>
            {items.map((product, i) => (
              <Fragment key={i}>
                <Grid item xs={6}>
                  <Card
                    sx={{
                      // maxWidth: { xs: 150, sm: 400, md: 600 },
                      margin: "0 auto",
                      padding: "0.1em",
                      justifyContent: "center",
                    }}
                    elevation={0}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        flexWrap: "wrap",
                      }}
                    >
                      <CardMedia
                        image={`${LOCAL_API}/product/image/${product.id}`}
                        title={product.name}
                        sx={{
                          height: "100px",
                          width: "100px",
                          objectFit: "contain",
                        }}
                      />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {" "}
                        <CardContent spacing={3}>
                          <Typography variant="subtitle1">
                            {product.product.name.substr(0, 20)}
                          </Typography>
                          <Typography>
                            Ksh {fCurrency(product.storePrice)}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Box>
                    <CardActions>
                      <Button
                        color="error"
                        size="small"
                        onClick={removeProduct(product.id)}
                      >
                        Remove
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    type="number"
                    value={product.count}
                    onChange={(event) =>
                      handleQuantityChange(event, product.id)
                    }
                  />
                </Grid>
                <Grid item xs={4} textAlign={"right"}>
                  <Typography
                    component={"div"}
                    variant="subtitle1"
                    fontWeight={"700"}
                  >
                    Ksh {fCurrency(product.count * product.storePrice)}
                  </Typography>
                </Grid>
                <Divider />
              </Fragment>
            ))}
          </Grid>
          <Toolbar />
          <div className="total-price">
            <table>
              <tbody>
                <tr>
                  <td>Subtotal</td>
                  <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "KSh",
                    }).format(cartTotal(cart))}
                  </td>
                </tr>
                <tr>
                  <td>VAT</td>
                  <td>KSh 0.00</td>
                </tr>
                <tr>
                  <td>Shipping</td>
                  <td>KSh 0.00</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "KSh",
                    }).format(getCartTotal(cart))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Checkout cart={cart} />
        </Paper>
      </Box>
    );
  };

  const noCartItemsMessage = () => (
    <>
      <div className="small-container">
        <div className="shopping-cart cart-page">
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Your shopping cart is empty.</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  return (
    <>
      {cart.length > 0 ? showCartItems(cart) : noCartItemsMessage()}
      {/* <div className="row">
        <div className="col-6">
          {cart.length > 0 ? showCartItems(cart) : noCartItemsMessage()}
        </div>
        <div className="col-6">
          <h2 className="mb-4">Your cart summary</h2>
          <hr />
          <Checkout cart={cart} setRun={setRun} run={run} />
        </div>
      </div> */}
    </>
  );
};

export default Cart;
