//import { LOCAL_API } from "../config";

//instance of axios with interceptors and has baseurl configured
import axios from "src/services/interceptors/api.interceptor";

export const read = async (userId, token) => {
  //The base url is set in the axios instance in
  return await axios.get(`/user/${userId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUserProfile = (userId, token, user) => {
  return axios.put(`/user/${userId}`, user, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPurchaseHistory = (userId, token) => {
  return axios.get(`/orders/by/user/${userId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUserLocalStorage = (user, next) => {
  if (typeof Window !== "undefined") {
    if (localStorage.getItem("jwt")) {
      let auth = JSON.parse(localStorage.getItem("jwt"));
      auth.user = user;
      localStorage.setItem("jwt", JSON.stringify(auth));
      next();
    }
  }
};
