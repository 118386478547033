import { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import {
  getConstituenciesForCounty,
  getCounties,
  getWardsForConstituency,
  postAddAddress,
} from "../../api/shippingAPI";
import { AuthContext } from "src/contexts/AuthContextProvider";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

const validationSchema = yup.object({
  fullnames: yup
    .string()
    .required("Name is required")
    .max(100, "Max length is 100"),
  mobile: yup
    .string()
    .required("Mobile phone number is required")
    .max(50, "Max length is 50"),
  address: yup
    .string()
    .required("Address number is required")
    .max(200, "Max length is 200"),
  countyId: yup.string().required("Country number required"),
  constituencyId: yup.string().required("Constituency is required"),
  wardId: yup.string().required("Ward is required"),
});
const ShippingAddressForm = () => {
  const [shippingAddress, setShippingAddress] = useState({
    fullnames: "",
    address: "",
    mobile: "",
    countyId: "",
    constituencyId: "",
    wardId: "",
  });
  const { fullnames, address, mobile, countyId, constituencyId, wardId } =
    shippingAddress;
  //Component's state variables
  const [counties, setCounties] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const [wards, setWards] = useState([]);

  //Authenticated user information.

  const { user } = useContext(AuthContext);

  //Shipping form ref
  const shippingForm = useRef(null);

  const handleSubmit = async (values) => {
    try {
      const response = await postAddAddress(shippingAddress, user?.user?.id);
      enqueueSnackbar(response.data.message, { variant: "success" });
      console.log(response.data);
    } catch (error) {
      enqueueSnackbar(
        "Adding address failed. Contact system admin if problem persists.",
        { variant: "error" }
      );
      console.log(error);
    }
  };

  const handleChange = (name) => async (event) => {
    setShippingAddress({ ...shippingAddress, [name]: event.target.value });
    console.log("Setting ", name, " to ", event.target.value);
    if (name === "countyId") {
      if (event.target.value !== "") {
        const constituencyRes = await getConstituenciesForCounty(
          event.target.value
        );
        if (constituencyRes.status === 200)
          setConstituencies(constituencyRes.data);
      } else {
        setConstituencies([]);
        setWards([]);
      }
    }
    if (name === "constituencyId") {
      if (event.target.value !== "") {
        const wardsRes = await getWardsForConstituency(event.target.value);
        if (wardsRes.status === 200) setWards(wardsRes.data);
      } else {
        setWards([]);
      }
    }
  };

  const loadCounties = async () => {
    const loadedCounties = await getCounties();
    setCounties(loadedCounties.data);
  };

  useEffect(() => {
    loadCounties();
  }, []);

  useEffect(() => {
    function setFullNames() {
      setShippingAddress({
        ...shippingAddress,
        fullnames: user.user.firstname + " " + user.user.lastname,
      });
    }
    setFullNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Formik
        onSubmit={handleSubmit}
        initialValues={shippingAddress}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => (
          <form
            ref={shippingForm}
            onSubmit={formik.handleSubmit}
            encType="multipart/form-data"
          >
            <Grid container spacing={3} sx={{ py: 2 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="fullnames"
                  label="Full Names"
                  value={fullnames}
                  onChange={handleChange("fullnames")}
                  error={
                    formik.touched.fullnames && Boolean(formik.errors.fullnames)
                  }
                  helperText={
                    formik.touched.fullnames && formik.errors.fullnames
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="mobile"
                  label="Mobile"
                  value={mobile}
                  onChange={handleChange("mobile")}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="address"
                  label="Pysical Address"
                  value={address}
                  onChange={handleChange("address")}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="county-select-label">County</InputLabel>
                  <Select
                    labelId="county-select-label"
                    id="county-select"
                    size="small"
                    value={countyId}
                    label="County"
                    onChange={handleChange("countyId")}
                  >
                    {counties &&
                      counties.length &&
                      counties.map((county, index) => (
                        <MenuItem key={index} value={`${county.id}`}>
                          {county.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="constituency-select-label">
                    Constituency
                  </InputLabel>
                  <Select
                    labelId="constituency-select-label"
                    id="county-select"
                    size="small"
                    value={constituencyId}
                    label="Constituency"
                    onChange={handleChange("constituencyId")}
                  >
                    {constituencies &&
                      constituencies.length &&
                      constituencies.map((constituency, index) => (
                        <MenuItem key={index} value={`${constituency.id}`}>
                          {constituency.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="ward-select-label">Ward</InputLabel>
                  <Select
                    labelId="ward-select-label"
                    id="ward-select"
                    size="small"
                    value={wardId}
                    label="Ward"
                    onChange={handleChange("wardId")}
                  >
                    {wards &&
                      wards.length &&
                      wards.map((ward, index) => (
                        <MenuItem key={index} value={`${ward.id}`}>
                          {ward.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <button type="submit" className="btn btn-primary">
              {"Add"}
            </button>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ShippingAddressForm;
