import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";
import { getBrands } from "../../../api/brandAPI";
import { getProducts } from "../../../api/productAPI";
import { addProductInventory } from "../../../api/productInventoryAPI";
import { getProductTypes } from "../../../api/productTypeAPI";
import { showServerResponseMessage } from "../../../helpers/ShowMessageHelper";
import { isAuthenticated } from "../../../services/auth";
import "../style.css";
import ProductInventoryForm from "./ProductInventoryForm";

const AddProductInventory = (props) => {
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [productInventory, setProductInventory] = useState({
    sku: "",
    upc: "",
    productType: {},
    product: {},
    media: [],
    rrPrice: "",
    storePrice: "",
    salePrice: "",
    inStock: "",
    brand: {},
    loading: false,
    status: { status: "", message: "" },
    formData: new FormData(),
  });

  const [products, setProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [brands, setBrands] = useState([]);

  //Destructure user and toker from localStorage
  const { user, token } = isAuthenticated();

  //Destructure state variable product inventory
  let { media, loading, status } = productInventory;

  //Response messages which are based on mostly server requests.
  //When status is changed, response message should pressumably update
  const [responseMessage, setResponseMessage] = useState(status);

  //useEffect hook to fetch all the categories
  useEffect(() => {
    const init = async () => {
      //Gets all products from the products table
      try {
        const result = await getProducts();
        if (result.status === 200) {
          setProducts(result.data);
        } else if (result.status === 401) {
          //Should redirect to login page with this page as an argument so that
          //when the user logs back in he/she is directed back to this page.
        }
      } catch (error) {
        console.log("Error getting product inventory:", error);
      }

      // Get product types so product type can be selected in the dropdown menu
      try {
        const result = await getProductTypes();
        if (result.status === 200) {
          setProductTypes(result.data);
        } else if (result.status === 401) {
          //Should redirect to login page with this page as an argument so that
          //when the user logs back in he/she is directed back to this page.
        }
      } catch (error) {
        console.log("Error getting product types:", error);
      }

      // Get all brands so we can assign an product invetory a brand name.
      // I think it should be put in products though.
      // Get product types so product type can be selected in the dropdown menu
      try {
        const result = await getBrands();
        if (result.status === 200) {
          setBrands(result.data);
        } else if (result.status === 401) {
          //Should redirect to login page with this page as an argument so that
          //when the user logs back in he/she is directed back to this page.
        }
      } catch (error) {
        console.log("Error getting Brands:", error);
      }
    };
    init();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    loading = true;
    try {
      const response = await addProductInventory(productInventory, user);
      setProductInventory({
        sku: "",
        upc: "",
        productType: {},
        product: {},
        media: [],
        rrPrice: "",
        storePrice: "",
        salePrice: "",
        inStock: "",
        brand: {},
        loading: false,
        status: {
          status: "success",
          message: `Product inventory created successfully.`,
          data: response.data,
        },
        formData: new FormData(),
      });
      loading = false;
      status = {
        status: "success",
        message: `Product inventory created successfully.`,
        data: response.data,
      };
      setResponseMessage(status);
      console.log("ProductInventory after insertng: ", status);
    } catch (error) {
      status = { status: "error", message: error.message };
      loading = false;
      setResponseMessage(status);
    }
  };

  const showUploadImage = () =>
    media &&
    media.length > 0 && (
      <div className="row uploaded-images">
        {media.map((image, i) => (
          <div className="col" key={i}>
            <img src={URL.createObjectURL(image)} alt={image.name} />
          </div>
        ))}
      </div>
    );
  const showLoading = () => (
    <div
      className="alert alert-info"
      style={{ display: loading ? "flex" : "none" }}
    >
      <h4>Loading ........</h4>
    </div>
  );
  const goBack = () => (
    <div className="mt-5">
      <Link className="text-warning" to="/admin/dashboard">
        Go back to dashboard
      </Link>
    </div>
  );

  return (
    <AdminBaseLayout
      {...props}
      title="Add new product"
      description={`Hello ${user.firstname}, are you ready to create new product?`}
    >
      <div className="small-container">
        <div className="row">
          <div className="col-2">
            <h2 className="title">Add Product Inventory</h2>
            {showLoading()}
            {showServerResponseMessage(responseMessage)}
            <ProductInventoryForm
              productInventory={productInventory}
              setProductInventory={setProductInventory}
              products={products}
              productTypes={productTypes}
              brands={brands}
              fileTypes={fileTypes}
              handleSubmit={handleSubmit}
              buttonName="Add Product Inventory"
            />
            {goBack()}
            {showUploadImage()}
          </div>
        </div>
      </div>
    </AdminBaseLayout>
  );
};
export default AddProductInventory;
