import App from "./App";
import AppRoutes from "./Routes";

import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import "src/assets/css/lightslider.css";
import "src/assets/css/style.css";
import AuthContextProvider from "./contexts/AuthContextProvider";
import CartContextProvider from "./contexts/ShoppingCartContextProvider";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <div>
    <CssBaseline />

    <SnackbarProvider anchorOrigin={{ horizontal: "center", vertical: "top" }}>
      <CartContextProvider>
        <AuthContextProvider>
          <AppRoutes>
            <App />
          </AppRoutes>
        </AuthContextProvider>
      </CartContextProvider>
    </SnackbarProvider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
