import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBrands } from "src/api/brandAPI";
import { getProducts } from "src/api/productAPI";
import {
  getProductInventoryById,
  updateProductInventory,
} from "src/api/productInventoryAPI";
import {
  getProductTypeAttributes,
  getProductTypes,
} from "src/api/productTypeAPI";
import Layout from "src/components/Layouts/BaseLayout/old-layout";
import { isAuthenticated } from "src/services/auth";
// import AdminNavigation from "../../user/AdminNavigation";
import { showServerResponseMessage } from "src/helpers/ShowMessageHelper";
import ProductAttributesForm from "../Products/ProductAttributesForm";
import "../style.css";
import ProductInventoryForm from "./ProductInventoryForm";

const UpdateProductInventory = (props) => {
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [files, setFiles] = useState(null);
  const [productInventory, setProductInventory] = useState({
    sku: "",
    upc: "",
    productType: {},
    product: {},
    media: [],
    rrPrice: "",
    storePrice: "",
    salePrice: "",
    inStock: "",
    brand: {},
    loading: false,
    status: { status: "", message: "" },
    formData: new FormData(),
  });

  const [products, setProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productTypeAttributes, setProductTypeAttributes] = useState([]);
  const [responseMessage, setResponseMessage] = useState();

  //Destructure user and toker from localStorage
  const { user, token } = isAuthenticated();

  const productInventoryId = props.match.params.productInventoryId;
  //Destructure state variable product
  let { media, loading, status } = productInventory;

  //useEffect hook to fetch all the categories
  useEffect(() => {
    const init = async () => {
      console.log("Initializing UpdateProductInventory component");
      //Get the product being updated from the database and store the result in
      //productInventory state variable
      try {
        const response = await getProductInventoryById(productInventoryId);
        setProductInventory(response.data);
        console.log(
          "ProductInventory returned by getProductInventoryById: ",
          response.data
        );
      } catch (error) {
        console.log("Error getting productInventoryById: ", error);
      }

      //Get all the product so the user can select the product they wish to Add
      //inventory for and set the value in products state variable and if there is an error
      //fetching the products I set the status variable in productInventory object.
      try {
        const response = await getProducts();
        setProducts(response.data);
        console.log(
          "ProductInventory returned by getProductInventoryById: ",
          response.data
        );
      } catch (error) {
        console.log("Error getting products: ", error);
      }

      // Get product types so product type can be selected in the dropdown menu
      try {
        const response = await getProductTypes();
        setProductTypes(response.data);
        console.log(
          "ProductTypes returned by getProductTypes: ",
          response.data
        );
      } catch (error) {
        console.log("Error getting products types: ", error);
      }

      // Get all brands so we can assign an product invetory a brand name.
      // I think it should be put in products though.
      // Get product types so product type can be selected in the dropdown menu
      try {
        const response = await getBrands();
        setBrands(response.data);
        console.log("ProductTypes returned by getBrands: ", response.data);
      } catch (error) {
        console.log("Error getting brands: ", error);
      }

      try {
        const response = await getProductTypeAttributes(12);
        setProductTypeAttributes(response.data);
        console.log(
          "ProductTypeAttributes returned by getProductTypeAttributes: ",
          response.data
        );
      } catch (error) {
        console.log("Error getting product type attributes: ", error);
      }
    };
    init();
  }, [productInventoryId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    loading = true;
    //Check to see if all the fields have been filled.

    try {
      const response = await updateProductInventory(
        productInventoryId,
        productInventory,
        user,
        token
      );
      loading = false;
      status = { status: "success", message: "Updated successfully." };
      setProductInventory(response.data);
      setResponseMessage(status);
    } catch (error) {
      loading = false;
      status = { status: "error", message: error.message };
      setResponseMessage(status);
      console.log("Error updating product inventory: ", error);
    }
  };

  const showUploadImage = () =>
    media &&
    media.length > 0 && (
      <div className="row uploaded-images">
        {media.map((image, i) => (
          <div className="col" key={i}>
            <img src={URL.createObjectURL(image)} alt={image.name} />
          </div>
        ))}
      </div>
    );

  const showLoading = () => (
    <div
      className="alert alert-info"
      style={{ display: loading ? "" : "none" }}
    >
      <h4>Loading ........</h4>
    </div>
  );
  const goBack = () => (
    <div className="mt-5">
      <Link className="text-warning" to="/admin/dashboard">
        Go back to dashboard
      </Link>
    </div>
  );

  return (
    <Layout
      {...props}
      title="Add new product"
      description={`Hello ${user.firstname}, are you ready to create new product?`}
    >
      <div className="small-container">
        <div className="row">
          <div className="col-2">
            <h2 className="title">Update product inventory</h2>
            {showLoading()}
            {showServerResponseMessage(responseMessage)}
            <div className="row product-inventory-forms-container">
              <ProductInventoryForm
                productInventoryId={productInventoryId}
                productInventory={productInventory}
                setProductInventory={setProductInventory}
                files={files}
                setFiles={setFiles}
                products={products}
                productTypes={productTypes}
                brands={brands}
                fileTypes={fileTypes}
                handleSubmit={handleSubmit}
                action="update"
              />
              <ProductAttributesForm
                productAttributes={productTypeAttributes}
              />
            </div>
            {goBack()}
            {showUploadImage()}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default UpdateProductInventory;
