import axios from "axios";
import { LOCAL_API } from "src/config";
import TokenService from "../auth/TokenService";

const instance = axios.create({
  baseURL: `${LOCAL_API}`,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    //console.log("interceptor intercepted request");
    return config;
  },
  (error) => {
    //console.log("Error inside axios request interceptor: ", error.message);
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    //console.log("Original config: ", err);
    if (originalConfig.url === "/auth/account" && err.response.status === 401) {
      return Promise.reject(err);
    }
    if (originalConfig.url !== "/auth/account" && err.response.status === 401) {
      //console.log("401 error from Original url: ", originalConfig.url);
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        //console.log("401 was returned. retrying to refresh token");
        originalConfig._retry = true;
        //console.log("refreshing access token in axios interceptor");
        try {
          const rs = await instance.post("/auth/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });
          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else {
        //
        // console.log(
        //   "Response if 401, has tried to refresh token and failed. Should take user login"
        // );
        TokenService.removeJWT();
        window.location.href = "/auth/account";
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
