import { Box, Paper } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useContext, useState } from "react";
import { addBrand } from "src/api/brandAPI";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";
import { AuthContext } from "src/contexts/AuthContextProvider";
import BrandForm from "./BrandForm";

const BrandDetails = (props) => {
  const [brand, setBrand] = useState({
    name: "",
    images: [],
    formData: new FormData(),
  });

  //Destructure user and toker from localStorage
  const { user } = useContext(AuthContext).user;
  let { formData } = brand;

  const handleSubmit = async () => {
    // Display the key/value pairs
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    try {
      const res = await addBrand(formData, user);

      if (res.status === 200)
        enqueueSnackbar(res.data.message, {
          variant: "success",
        });
      setBrand({ name: "", images: [], formData: new FormData() });
    } catch (error) {
      console.log("An error occured adding brand: ", JSON.stringify(error));
      enqueueSnackbar(error, {
        variant: "error",
      });
      if (error.response.status === 401) {
        // setServerResponse({
        //   status: "error",
        //   message: "Session has expired, logout and in again.",
        // });
        enqueueSnackbar("Session has expired", {
          variant: "error",
        });
      } else {
        // setServerResponse({
        //   status: "error",
        //   message:
        //     "An error occured communicating with server. Try again later or contact system admin if problem persist.",
        // });
        enqueueSnackbar(
          "An error occured communicating with server. Try again later or contact system admin if problem persist.",
          {
            variant: "error",
          }
        );
      }
    }

    return false;
  };

  return (
    <AdminBaseLayout>
      <Paper elevation={3} className="small-container">
        <h2 className="title">Add Brand</h2>
        <Box className="row">
          <BrandForm
            brand={brand}
            setBrand={setBrand}
            handleSubmit={handleSubmit}
          />
        </Box>
      </Paper>
    </AdminBaseLayout>
  );
};

export default BrandDetails;
