import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import { Box } from "@mui/material";
const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col-1">
            <h3>Download our app</h3>
            <p>Download App for Apple and Android.</p>
            <Box sx={{ alignContent: "baseline" }}>
              <AndroidIcon />
              <AppleIcon />
              {/* <List>
                <ListItem>
                  <AndroidIcon />
                </ListItem>
                <ListItem>
                  <AppleIcon  />
                </ListItem>
              </List> */}
            </Box>
          </div>
          <div className="footer-col-2">
            <img src="images/logo_light.png" alt="" />
            <p>
              Our purpose is to sustainability make the pleasure and benefites
              of sports accessible to the many.
            </p>
          </div>
          <div className="footer-col-3">
            <h3>Useful Links</h3>
            <ul>
              <li>Coupons</li>
              <li>Blog Pos</li>
              <li>Return Policy</li>
              <li>Join Affiliate</li>
            </ul>
          </div>
          <div className="footer-col-3">
            <h3>Follow Us</h3>
            <ul>
              <li>
                <Facebook />
              </li>
              <li>
                <Twitter />
              </li>
              <li>
                <Instagram />
              </li>
              <li>
                <LinkedIn />
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <p className="copyright">
          Copyright {new Date().getFullYear()} - Duka La Jamii
        </p>
      </div>
    </div>
  );
};
export default Footer;
