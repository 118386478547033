import axios from "axios";
import { LOCAL_API } from "../config";
import getCommonOptions from "src/helpers/axios/getCommonOptions";
export const addProductInventory = (productInventory, user) => {
  console.log("addProductInventory, ", productInventory);
  return axios.post(
    `${LOCAL_API}/admin/product-inventory/add/${user.id}`,
    productInventory,
    getCommonOptions()
  );
};

export const updateProductInventory = (
  productInventoryId,
  productInventory,
  user
) => {
  return axios.put(
    `${LOCAL_API}/admin/product-inventory/update/${productInventoryId}/${user.id}`,
    { ...productInventory, updatedAt: new Date() },
    getCommonOptions()
  );
};

export const getProductInventory = (page, limit) => {
  return axios.get(
    `${LOCAL_API}/product-inventory?page=${page}&limit=${limit}`
  );
};
export const getProductInventorySize = () => {
  return axios.get(`${LOCAL_API}/product-inventory/size`);
};
export const getFilteredProductInventory = (page, limit, filters) => {
  return axios.post(
    `${LOCAL_API}/product-inventory?page=${page}&limit=${limit}`,
    filters
  );
};
export const getProductInventories = () => {
  return axios.get(`${LOCAL_API}/product-inventories`);
};
export const getProductInventoryById = (id) => {
  return axios.get(`${LOCAL_API}/product-inventory/${id}`);
};
export const getProductInventoryByProductId = (id) => {
  return axios.get(`${LOCAL_API}/product-inventory-by-product-id/${id}`);
};
export const getProductInventoryImages = (id) => {
  return axios.get(`${LOCAL_API}/product-inventory/images/${id}`);
};
