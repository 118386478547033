import getCommonOptions from "src/helpers/axios/getCommonOptions";
import { LOCAL_API } from "../config";
import axios from "axios";
export const createCategory = (formData, user, token) => {
  console.log("Form data: ", formData);
  return fetch(`${LOCAL_API}/admin/category/create/${user.id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      //,'Content-Type': 'multipart/form-data'
    },
    body: formData,
  })
    .then((data) => data.json())
    .catch((error) => console.log(error));
};

export const createProduct = (product, user) => {
  return axios.post(
    `${LOCAL_API}/admin/product/add/${user.id}`,
    JSON.stringify(product),
    getCommonOptions()
  );
  // return fetch(`${LOCAL_API}/admin/product/add/${user.id}`, {
  //   method: "POST",
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(product),
  // })
  //   .then((data) => {
  //     return data.json();
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
};
export const addProductInventory = (productInventory, user, token) => {
  return fetch(`${LOCAL_API}/admin/product-inventory/add/${user.id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      //"Content-Type": "application/json",
    },
    body: productInventory.formData,
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateProductInventory = (
  productInventoryId,
  productInventory,
  user,
  token
) => {
  return fetch(
    `${LOCAL_API}/admin/product-inventory/update/${productInventoryId}/${user.id}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productInventory),
    }
  )
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getProductInventory = () => {
  return fetch(`${LOCAL_API}/product-inventory`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getProductInventoryById = (id) => {
  return fetch(`${LOCAL_API}/product-inventory/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getProductTypeAttributes = (productTypeId) => {
  return fetch(`${LOCAL_API}/product-type/${productTypeId}/attributes/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
export const createProductType = (productType, user, token) => {
  return axios.post(
    `${LOCAL_API}/admin/product-type/create/${user.id}`,
    productType,
    getCommonOptions()
  );
};

export const getProductTypes = () => {
  return fetch(`${LOCAL_API}/product-types`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addBrand = (brand, user, token) => {
  console.log(brand);
  return fetch(`${LOCAL_API}/admin/brand/add/${user.id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      //"Content-Type": "application/json",
      //"Content-Type": "multipart/form-data",
    },
    body: brand,
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getBrands = () => {
  return fetch(`${LOCAL_API}/brands`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

//Get all categories
export const getCategories = () => {
  // return fetch(`${LOCAL_API}/category`, {
  //   method: "GET",
  // })
  //   .then((data) => {
  //     return data.json();
  //   })
  //   .catch((err) => {
  //     console.log("Error getting categories. ", err);
  //   });
  return axios.get(`${LOCAL_API}/categories`);
};

//get all orders
export const listOrders = (userId, token) => {
  return fetch(`${LOCAL_API}/orders/list/${userId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getStatusValues = (userId, token) => {
  return fetch(`${LOCAL_API}/orders/status-values/${userId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateOrderStatus = (userId, token, data) => {
  console.log("Status value in client ", data);
  return fetch(`${LOCAL_API}/order/${data.orderId}/status/${userId}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

/*
 * To perform crud on product
 * get all products
 * get single product
 * update single product
 * delete single product
 */

export const getProducts = () => {
  return fetch(`${LOCAL_API}/products`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getProductById = (productId) => {
  return fetch(`${LOCAL_API}/product/${productId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteProduct = (productId, userId, token) => {
  return fetch(`${LOCAL_API}/product/${productId}/${userId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
export const updateProduct = (productId, userId, token, product) => {
  console.log("updateProduct productId and product form", productId, product);
  return fetch(`${LOCAL_API}/product/${productId}/${userId}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: product,
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getProductsAttributes = () => {
  return fetch(`${LOCAL_API}/products/attributes`, {
    method: "GET",
  })
    .then((data) => {
      return data.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addProductsAttributes = (userId, token, attributes) => {
  return fetch(`${LOCAL_API}/products/attributes/${userId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: attributes,
  })
    .then((data) => {
      return data.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
