export const addItem = (item, next) => {
  let cart = [];
  if (typeof Window !== "undefined") {
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }
    cart.push({
      ...item,
      count: item.count > 0 ? item.count : 1,
    });
  }

  //Remove duplicates
  //build an array from new set and turn it back into array using Array.from
  //so that later we can re-map it
  //new set will only allow unique values in it
  //so pass the ids for each object/product
  //If the loop tries to add the same value again, it'll be ignored
  //...with the array so ids we got on when first map() was used
  //run map() on it again and return the actual product from the cart
  cart = Array.from(new Set(cart.map((p) => p.id))).map((id) => {
    return cart.find((p) => p.id === id);
  });

  localStorage.setItem("cart", JSON.stringify(cart));
  next();
};

export const itemsTotal = () => {
  if (typeof Window !== "undefined") {
    if (localStorage.getItem("cart")) {
      return JSON.parse(localStorage.getItem("cart")).length;
    }
    return 0;
  }
};

export const cartTotalItems = () => {
  if (typeof Window !== "undefined") {
    if (localStorage.getItem("cart")) {
      return JSON.parse(localStorage.getItem("cart")).length;
    }
    return 0;
  }
};

export const getCart = () => {
  if (typeof Window !== "undefined") {
    if (localStorage.getItem("cart")) {
      return JSON.parse(localStorage.getItem("cart"));
    }
    return [];
  }
};
export const updateItem = (productId, count) => {
  let cart = [];
  if (typeof Window !== "undefined") {
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }

    cart.map((product, i) => {
      if (product.id === productId) {
        cart[i].count = count;
      }
      return product;
    });

    localStorage.setItem("cart", JSON.stringify(cart));
  }
};

export const removeItem = (productInventoryId) => {
  let cart = [];
  if (typeof Window !== "undefined") {
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }

    cart.map((productInventory, i) => {
      if (productInventory.id === productInventoryId) {
        cart.splice(i, 1);
      }
    });

    localStorage.setItem("cart", JSON.stringify(cart));

    return cart;
  }
};

export const cartTotal = (cart) => {
  return cart.reduce((total, currentValue) => {
    return total + currentValue.count * currentValue.storePrice;
  }, 0);
};

export const emptyCart = (next) => {
  if (typeof Window !== "undefined") {
    localStorage.removeItem("cart");
    next();
  }
};
