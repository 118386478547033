import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "src/contexts/AuthContextProvider";
export default function RequireNoAuth() {
  const { isAuthenticated, user } = React.useContext(AuthContext);
  const role = user?.user?.role;

  if (isAuthenticated === null) {
    return <div>Loading.....</div>;
  }

  if (isAuthenticated === true) {
    if (role && role === 1) return <Navigate to="/admin/dashboard" />;
    if (role && role === 0) return <Navigate to="/user/dashboard" />;
    return <Navigate to="/" />;
  }
  return <Outlet />;
}
