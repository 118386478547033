import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  GlobalStyles,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";

const drawerWidth = 240;

const SidebarGlobalStyles = () => {
  const theme = useTheme();
  return (
    <GlobalStyles
      styles={{
        ".sidebar-nav-item": {
          color: "unset",
          textDecoration: "none",
        },
        ".sidebar-nav-item-active": {
          textDecoration: "none",
          color: theme.palette.primary.main,
          "& .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
          "& .MuiTypography-root": {
            fontWeight: 500,
            color: theme.palette.primary.main,
          },
        },
      }}
    />
  );
};
const SidebarGlobalStylesMemo = React.memo(SidebarGlobalStyles);

export function SideMenu(props) {
  const [checked, setChecked] = useState([]);

  const { mobileOpen, setMobileOpen, categories, handleFilters } = props;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleToggle = (c) => () => {
    console.log("handleToggle called");
    //return the first index or -1
    const currentCategoryId = checked.indexOf(c);
    const newCheckedCategoryId = [...checked];
    //if currently checked was not already in checked state > push
    //else pull/take off
    if (currentCategoryId === -1) {
      newCheckedCategoryId.push(c);
    } else {
      newCheckedCategoryId.splice(currentCategoryId, 1);
    }

    console.log("Checked category id: ", newCheckedCategoryId);
    setChecked(newCheckedCategoryId);
    handleFilters(newCheckedCategoryId);
  };

  const drawer = (
    <Box>
      <Toolbar />
      <Toolbar />
      {/* <Divider /> */}
      <List>
        <FormGroup>
          {categories.map((category, idx) => {
            return (
              <ListItem key={idx}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleToggle(category.id)}
                      value={category.id}
                      // value={checked.indexOf(category.id === -1)}
                    />
                  }
                  label={category.name}
                />
              </ListItem>
            );
          })}
        </FormGroup>
      </List>
      <Divider />
    </Box>
  );

  return (
    <Box
      component="div"
      sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <SidebarGlobalStylesMemo />
      {/* This drawer is shown on small and extra small screens and not from medium screen size */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      {/* This drawer is shown only on medium  screens upwards, small and xs is not shown. */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

SideMenu.propTypes = {
  mobileOpen: PropTypes.bool,
  setMobileOpen: PropTypes.func.isRequired,
  categories: PropTypes.array,
};

export default SideMenu;
