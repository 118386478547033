import React from "react";

import { Box } from "@mui/system";
import AppHeader from "./AppHeader";
import SideMenu from "./SideMenu";

function AdminBaseLayout(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <Box>
        <AppHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <SideMenu mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      </Box>
      {/* <LoadingOverlayResource> */}
      <Box
        sx={{
          flexGrow: 1,
          padding: (theme) => theme.spacing(1),
          mt: 10,
        }}
      >
        {props.children}
      </Box>
      {/* </LoadingOverlayResource> */}
    </Box>
  );
}

export default AdminBaseLayout;
