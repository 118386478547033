class TokenService {
  getLocalRefreshToken = () => {
    if (typeof window === "undefined") return false;
    const jwt = localStorage.getItem("jwt");
    return jwt?.refreshToken;
  };
  getLocalAccessToken = () => {
    if (typeof window === "undefined") return false;
    if (localStorage.getItem("jwt")) {
      return localStorage.getItem("jwt");
    } else {
      return false;
    }
  };
  getCurrentUser = () => {
    if (typeof window == "undefined") return false;
    if (localStorage.getItem("jwt")) {
      const jwt = localStorage.getItem("jwt");
      return jwt.user;
    } else {
      return false;
    }
  };
  getJWT = () => {
    if (typeof window == "undefined") return false;
    if (localStorage.getItem("jwt")) {
      return localStorage.getItem("jwt");
    } else {
      return false;
    }
  };
  updateLocalAccessToken = (token) => {
    if (typeof window === "undefined") return false;
    const jwt = localStorage.getItem("jwt");
    jwt.token = token;
    localStorage.setItem("jwt", jwt);
    return true;
  };
  setJWT(jwt) {
    if (typeof window === "undefined") return false;
    localStorage.setItem("jwt", jwt);
    return true;
  }
  removeJWT = () => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("jwt");
      return true;
    } else {
      return false;
    }
  };
}
const tokenService = new TokenService();
export default tokenService;
