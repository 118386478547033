import ProductPage from "./view/ProductPage";

const ManageProducts = () => {
  return <ProductPage />;
};
export default ManageProducts;
// import { Box, Paper } from "@mui/material";
// import { useContext, useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { deleteAProduct, getProducts, searchProduct } from "src/api/productAPI";
// import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout";
// import { AuthContext } from "src/contexts/AuthContextProvider";
// const ManageProducts = (props) => {
//   // const [product, setProduct] = useState({
//   //   name: "",
//   //   slug: "",
//   //   description: "",
//   //   chosenCategories: [],
//   //   category: "",
//   //   loading: false,
//   //   error: "",
//   //   status: { status: "", message: "" },
//   // });
//   const [products, setProducts] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [serverResponse, setServerResponse] = useState({
//     status: "",
//     message: "",
//   });

//   const { user, token } = useContext(AuthContext);

//   const navigate = useNavigate();

//   const searchInput = useRef();

//   const search = async (e) => {
//     let searchQuery = searchInput.current.value;
//     setSearchTerm(searchQuery);
//     try {
//       const res = await searchProduct(searchQuery);
//       console.log("Res from search ", res);
//       setProducts(res.data);
//     } catch (error) {
//       console.log(error.message);
//     }
//     console.log("Search clicked and searching for: " + searchQuery);
//   };

//   const loadProducts = async () => {
//     try {
//       const res = await getProducts();
//       if (res.status === 200) setProducts(res.data);
//     } catch (error) {
//       console.log(error.message);
//     }
//   };

//   const destroyProduct = async (web_id) => {
//     try {
//       const res = await deleteAProduct(web_id, user?.user.id, token);
//       console.log("Deleting product: ", res.data);
//       setServerResponse(res.data);
//       loadProducts();
//     } catch (error) {
//       console.log("Error deleting product: ", error);
//     }
//   };

//   const showProducts = () => (
//     //console.log(products);
//     <table className="table">
//       <thead>
//         <tr>
//           <th scope="col">Product</th>
//           <th scope="col">description</th>
//           <th scope="col">Product variations</th>
//           <th scope="col">Actions</th>
//         </tr>
//       </thead>
//       <tbody>
//         {products &&
//           products.length > 0 &&
//           products.map((product, idx) => {
//             return (
//               <tr key={idx}>
//                 <td>{product.name}</td>

//                 <td>
//                   {product.description.substring(0, 150)}
//                   {product.description.length >= 150 ? "..." : ""}
//                 </td>
//                 <td>{product.count}</td>
//                 <td data-source="Actions">
//                   <button>
//                     <i className="bx bx-edit"></i>
//                   </button>
//                   <button>
//                     <i
//                       className="bx bx-trash"
//                       onClick={() => {
//                         console.log("Trash button clicked");
//                         destroyProduct(product.web_id);
//                       }}
//                     ></i>
//                   </button>
//                 </td>
//               </tr>
//             );
//           })}
//         {products && products.length < 1 && (
//           <tr>
//             <td colSpan={4} style={{ textAlign: "left" }}>
//               No products found
//             </td>
//           </tr>
//         )}
//       </tbody>
//     </table>
//   );
//   useEffect(() => {
//     searchInput.current.addEventListener("keypress", (event) => {
//       if (event.key === "Enter") {
//         event.preventDefault();
//         search(event);
//       }
//     });
//   }, []);
//   useEffect(() => {
//     loadProducts();
//   }, [props]);

//   return (
//     <AdminBaseLayout>
//       <Box className="small-container" component={Paper} elevation={3}>
//         <h2 className="title">Products</h2>
//         <div className="mgmt-container">
//           <div className="mgmt-list-wrapper">
//             <div className="table">
//               <div className="table-header">
//                 <p>
//                   {products?.length} product
//                   {products?.length > 1 ? "s" : ""}
//                 </p>
//                 <div className="th-actions">
//                   <div className="search-bar">
//                     <input
//                       type="text"
//                       ref={searchInput}
//                       placeholder="Product"
//                     />
//                     <span>
//                       <i
//                         className="bx bx-search"
//                         onClick={(e) => search(e)}
//                       ></i>
//                     </span>
//                   </div>

//                   <button
//                     className="add-new"
//                     onClick={() => navigate("/admin/product")}
//                   >
//                     + Add New
//                   </button>
//                 </div>
//               </div>
//               <div className="table-section">{showProducts()}</div>
//             </div>
//           </div>
//           <div className="mgmt-form-wrapper">
//             {/* <ProductForm
//               product={product}
//               setProduct={setProduct}
//               serverResponse={serverResponse}
//               setServerResponse={setServerResponse}
//             /> */}
//           </div>
//         </div>
//       </Box>
//     </AdminBaseLayout>
//   );
// };

// export default ManageProducts;
