import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import BaseLayout from "src/components/Layouts/BaseLayout/old-layout";
import LoginForm from "src/pages/Auth/LoginForm";
import RegisterForm from "src/pages/Auth/RegisterForm";

const Account = (props) => {
  const [loading, setLoading] = useState(false);
  const loginBtnRef = useRef(null);
  const registerBtnRef = useRef(null);
  const loginForm = useRef(null);
  const registerForm = useRef(null);
  const indicatorRef = useRef(null);

  const registerBtnClicked = (e) => {
    registerForm.current &&
      (registerForm.current.style.transform = "translateX(-300px)");
    loginForm.current &&
      (loginForm.current.style.transform = "translateX(-300px)");
    indicatorRef.current &&
      (indicatorRef.current.style.transform = "translateX(100px)");
  };
  const loginBtnClicked = (e) => {
    console.log("login button clicked: ", loginForm);
    console.log("Login form content: ", loginForm);
    loginForm.current &&
      (loginForm.current.style.transform = "translateX(0px)");
    registerForm.current &&
      (registerForm.current.style.transform = "translateX(300px)");

    indicatorRef.current &&
      (indicatorRef.current.style.transform = "translateX(0px)");
  };

  const showLoading = () => (
    <div style={{ display: loading ? "flex" : "none" }}>
      <div className="loader"></div>
    </div>
  );
  const init = () => {
    console.log("login form content in init: ", loginForm);
    console.log("Register form content in init: ", registerForm);
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-2">
            {/* <img src="/images/banner.jpg" alt="Logo" /> */}
          </div>
          <div className="col-2">
            <Box className="form-container">
              {showLoading()}
              <Box className="form-btn">
                <span ref={loginBtnRef} onClick={(e) => loginBtnClicked(e)}>
                  Login
                </span>
                <span
                  ref={registerBtnRef}
                  onClick={(e) => registerBtnClicked(e)}
                >
                  Register
                </span>
                <hr ref={indicatorRef} id="indicator" />
              </Box>
              <Box sx={{}}>
                <RegisterForm
                  ref={registerForm}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Box>
              <Box>
                <LoginForm
                  ref={loginForm}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
export default Account;
