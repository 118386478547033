import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useRequestAuth from "src/hooks/useRequestAuth";
function Logout() {
  const navigate = useNavigate();
  const { logout } = useRequestAuth();
  useEffect(() => {
    logout();
    navigate("/");
  }, [logout, navigate]);
  return <div>index</div>;
}

export default Logout;
