import { useEffect, useRef, useState } from "react";
import { getCategories } from "src/api/categoriesAPI";
import Card from "src/components/reusable/Card";

const Search = () => {
  const [data, setData] = useState({
    categories: [],
    category: "",
    search: "",
    results: [],
    searched: false,
  });

  const searchInput = useRef(null);

  const { categories, category, search, results, searched } = data;

  const loadCategories = async () => {
    try {
      const res = await getCategories();
      setData({ ...data, categories: res.data });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Search useEffects.");
    loadCategories();
  }, []);

  const handleChange = (name) => (event) => {
    setData({ ...data, [name]: event.target.value, searched: false });
  };

  const searchData = () => {
    console.log(search, category);
    if (search) {
      //   list({ search: search || undefined, category: category }).then((res) => {
      //     if (res.error) {
      //       console.log(res.error);
      //     } else {
      //       setData({ ...data, results: res, searched: true });
      //     }
      //   });
    }
  };

  const searchSubmit = (e) => {
    e.preventDefault();
    searchData();
  };

  const searchMessage = (searched, results) => {
    if (searched && results.length > 0) {
      return `Found ${results.length} products`;
    }
    if (searched && results.length < 1) {
      return `No products found`;
    }
  };

  const searchedProducts = (results = []) => {
    return (
      <div>
        <h2 className="mt-4 mb-4">{searchMessage(searched, results)}</h2>

        <div className="row">
          {results.map((product, i) => (
            <Card key={i} product={product} />
          ))}
        </div>
      </div>
    );
  };

  const searchForm = () => (
    <form onSubmit={searchSubmit} className="mb-4">
      <span className="input-group-text">
        <div className="input-group input-group-lg">
          <div className="input-group-prepend">
            <select className="btn mr-2" onChange={handleChange("category")}>
              <option value="all">Pick Category</option>
              {categories &&
                categories.map((c, i) => {
                  return (
                    <option key={i} value={c.id}>
                      {c.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <input
            type="search"
            className="form-control"
            onChange={handleChange("search")}
            placeholder="Search by name"
          />
        </div>
        <div className="btn input-group-append">
          <button className="input-group-text">Search</button>
        </div>
      </span>
    </form>
  );

  return (
    <>
      <div className="small-container">
        <div className="table">
          <div className="table-header">
            <div>Searched:</div>
            <div className="th-actions">
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search Product"
                  id=""
                  ref={searchInput}
                />
                <span>
                  <i
                    className="bx bx-search"
                    onClick={(e) => {
                      console.log("search clicked");
                    }}
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">{searchedProducts(results)}</div>
      </div>
    </>
  );
};
export default Search;
