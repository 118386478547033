import React, { useState, useEffect } from "react";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";
import {
  getStatusValues,
  listOrders,
  updateOrderStatus,
} from "src/api/apiAdmin";
import moment from "moment";
import { isAuthenticated } from "src/services/auth";
const Order = (props) => {
  const [orders, setOrders] = useState([]);
  const [statusValues, setStatusValues] = useState({});
  const [error, setError] = useState(false);

  const userId = isAuthenticated().user._id;
  const token = isAuthenticated().token;

  useEffect(() => {
    loadOrders(userId, token);
    loadStatusValues(userId, token);
  }, []);

  const loadOrders = (userId, token) => {
    listOrders(userId, token).then((data) => {
      if (data && data.error) {
        setError(error);
      } else {
        setError(false);
        setOrders(data);
      }
    });
  };

  const loadStatusValues = (userId, token) => {
    getStatusValues(userId, token).then((data) => {
      if (data && data.error) {
        console.log("Error ", data.error);
        setError(error);
      } else {
        console.log("status ", data);
        setError(false);
        setStatusValues(data);
      }
    });
  };
  const showStatus = (order) => (
    <div className="form-group">
      <h5 className="mark mb-4">Status: {order.status}</h5>
      <select
        onChange={(e) => handleStatusChange(order._id, e)}
        className="form-control"
      >
        <option value="">Update status</option>
        {statusValues.map((status, sIndex) => {
          return (
            <option key={sIndex} value={status}>
              {status}
            </option>
          );
        })}
      </select>
    </div>
  );
  const handleStatusChange = (orderId, event) => {
    console.log("userId & Token: ", userId, token);
    const data = {
      orderId,
      status: event.target.value,
    };
    updateOrderStatus(userId, token, data).then((data) => {
      if (data.error) {
        console.log(error);
      } else {
        console.log("Status update ", data);
        loadOrders(userId, token);
      }
    });
  };
  const showError = () => {
    if (error) {
      return (
        <div
          className="alert alert-danger"
          style={{ display: error ? "" : "none" }}
        >
          {error}
        </div>
      );
    } else {
      return <></>;
    }
  };
  const showOrderLength = () => {
    if (orders.length > 0) {
      return (
        <h4 className="text-info display-4">Total orders: {orders.length}</h4>
      );
    } else {
      return <h4 className="text-danger">No orders</h4>;
    }
  };

  const showInput = (key, value) => (
    <div className="input-group mb-2 mr-sn-2">
      <div className="input-group-prepend">
        <div className="input-group-text">{key}</div>
      </div>
      <input type="text" value={value} className="form-control" readOnly />
    </div>
  );

  const showOrders = () => {
    return (
      orders &&
      orders.map((order, oIndex) => (
        <div
          key={oIndex}
          className="mt-5"
          style={{ borderBottom: "5px solid indigo" }}
        >
          <h4 className="mb-5">
            <span className="bg-primary">Order ID: {order._id}</span>
          </h4>
          {showStatus(order)}
          <ul className="list-group mb-2">
            <li className="list-group-item">Status: {order.status}</li>
            <li className="list-group-item">
              Transaction ID: {order.transaction_id}
            </li>
            <li className="list-group-item">Amount: ${order.amount}</li>
            <li className="list-group-item">
              Ordered By: {order.user.firstname} {order.user.lastname}
            </li>
            <li className="list-group-item">
              Ordered: {moment(order.createdAt).fromNow()}
            </li>
            <li className="list-group-item">
              Shipping Address: {order.address}
            </li>
          </ul>
          <h4 className="mt-4 mb-4 font-italic">
            Total products in the order: {order.products.length}
          </h4>
          {order.products.map((product, pIndex) => (
            <div
              className="mb-4"
              key={pIndex}
              style={{ padding: "20px", border: "1px solid indigo" }}
            >
              {showInput("Product Name ", product.name)}
              {showInput("Product Price ", `$${product.price}`)}
              {showInput("Product Total ", product.count)}
              {showInput("Product ID ", product._id)}
            </div>
          ))}
        </div>
      ))
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <AdminBaseLayout
      {...props}
      title="Orders Page"
      description="View all orders and update status"
      className="container"
    >
      <div className="small-container">
        <div className="row">
          <div className="col-2">
            <h2 className="title">Orders</h2> <i className="bx bx-cart"></i>
            {showOrderLength()}
            {showError()}
            {showOrders()}
          </div>
        </div>
      </div>
    </AdminBaseLayout>
  );
};

export default Order;
