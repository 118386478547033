import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PropTypes } from "prop-types";
export const ShoppingCartContext = React.createContext({
  setShoppingCart: null,
  cartTotalItems: () => {},
  cartTotalCost: () => {},
  updateCartItem: () => {},
  removeCartItem: () => {},
  emptyCart: () => {},
});

function ShoppingCartContextProvider({ children }) {
  const [shoppingCart, setShoppingCart] = useState(null);

  const loadCart = () => {
    if (typeof Window !== "undefined") {
      if (localStorage.getItem("cart")) {
        // console.log(
        //   "Shopping cart has already been initialized in localStorage. Setting shoppingCart Session."
        // );
        setShoppingCart(JSON.parse(localStorage.getItem("cart")));
      } else {
        console.log("No shopping cart in local storage.");
        console.log("ShoppingCart contents: ", shoppingCart);
      }
    }
  };
  const cartTotalItems = useCallback(() => {
    if (shoppingCart) {
      try {
        return shoppingCart.length;
      } catch (error) {}
    }
    return 0;
  }, [shoppingCart]);

  const addCartItem = useCallback(
    (item, next) => {
      let cart = shoppingCart;
      if (cart) {
        cart.push({
          ...item,
          count: item.count > 0 ? item.count : 1,
        });
      }

      //Remove duplicates
      //build an array from new set and turn it back into array using Array.from
      //so that later we can re-map it
      //new set will only allow unique values in it
      //so pass the ids for each object/product
      //If the loop tries to add the same value again, it'll be ignored
      //...with the array so ids we got on when first map() was used
      //run map() on it again and return the actual product from the cart
      cart = Array.from(new Set(cart.map((p) => p.id))).map((id) => {
        return cart.find((p) => p.id === id);
      });
      setShoppingCart(cart);
      localStorage.setItem("cart", JSON.stringify(cart));
      next();
    },
    [shoppingCart]
  );

  const updateCartItem = useCallback(
    (productId, count) => {
      let cart = [];
      if (shoppingCart) {
        cart = shoppingCart;

        const newCart = cart.map((product, i) => {
          if (product.id === productId) {
            if (count > 0) cart[i].count = count;
            if (count < 1) cart.splice(i, 1);
          }
          return product;
        });
        setShoppingCart(newCart);
        localStorage.setItem("cart", JSON.stringify(newCart));
      }
    },
    [shoppingCart]
  );

  const removeCartItem = useCallback(
    (productInventoryId) => {
      let cart = shoppingCart;
      cart.map((productInventory, i) => {
        if (productInventory.id === productInventoryId) {
          cart.splice(i, 1);
        }
        return productInventory;
      });
      setShoppingCart(cart);
      localStorage.setItem("cart", JSON.stringify(cart));

      return cart;
    },
    [shoppingCart]
  );

  const cartTotalCost = useCallback(() => {
    return shoppingCart.reduce((total, currentValue) => {
      return total + currentValue.count * currentValue.storePrice;
    }, 0);
  }, [shoppingCart]);

  const emptyCart = useCallback(
    (next) => {
      setShoppingCart(null);
      if (typeof Window !== "undefined") {
        localStorage.removeItem("cart");
        next();
      }
    },
    [setShoppingCart]
  );

  const providerValue = useMemo(() => {
    return {
      shoppingCart,
      setShoppingCart,
      addCartItem,
      cartTotalItems,
      cartTotalCost,
      updateCartItem,
      removeCartItem,
      emptyCart,
    };
  }, [
    shoppingCart,
    setShoppingCart,
    addCartItem,
    cartTotalItems,
    cartTotalCost,
    updateCartItem,
    removeCartItem,
    emptyCart,
  ]);

  useEffect(() => {
    if (!shoppingCart) loadCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingCart]);

  return (
    <ShoppingCartContext.Provider value={providerValue}>
      {children}
    </ShoppingCartContext.Provider>
  );
}
ShoppingCartContextProvider.propTypes = {
  children: PropTypes.node,
};
export default ShoppingCartContextProvider;
