import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { read } from "src/api/apiUser";
import { getShippingAddresses } from "src/api/shippingAPI";
import { AuthContext } from "src/contexts/AuthContextProvider";

const Profile = (props) => {
  const { user, token } = useContext(AuthContext);
  const [dbUser, setDbUser] = useState({});
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const params = useParams();

  const init = async (userId) => {
    try {
      const res = await read(params.userId, token);
      setDbUser(res);
    } catch (error) {
      console.log("Error getting user by id: ", error);
    }
    try {
      const res = await getShippingAddresses(user.id, token);
      setShippingAddresses(res);
    } catch (err) {
      console.log("Error getting shipping addresses: ", err);
    }
    console.log("UserId ", userId);
  };

  useEffect(() => {
    init(params.userId);
  }, [params.userId]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="small-container">
        <div className="sub-container">
          <div className="sub-container-content">
            <h2 className="title">My Account</h2>
            <div className="account-info">
              <div className="custom-card">
                <div className="header">Account Details</div>
                <div className="content">
                  <p className="names">
                    {user?.user.firstname} {dbUser.lastname}
                  </p>
                  <p className="names">{user.email}</p>
                  <p className="names">{dbUser.mobile}</p>
                </div>
              </div>
              <div className="custom-card">
                <div className="header">Address Book</div>
                <div className="content">
                  <p className="names">
                    {user.firstname} {user.lastname}
                  </p>
                  <p className="names">{user.email}</p>
                  <p className="names">{user.mobile}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile;
