import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getProductById, updateProduct } from "src/api/productAPI";
import { validateProductForm } from "src/helpers/FormValidator";
import { isAuthenticated } from "src/services/auth";
import ProductForm from "./ProductForm";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";
const UpdateProduct = (props) => {
  const [product, setProduct] = useState({
    name: "",
    slug: "",
    description: "",
    chosenCategories: [],
    category: "",
    loading: false,
    error: "",
    status: { status: "", message: "" },
  });
  //Destructure state variable product
  let { name, slug, description, chosenCategories, formData, loading, status } =
    product;

  //Set responseMessage to the status of product so whenever
  const [responseMessage, setResponseMessage] = useState(status);
  //Destructure user and toker from localStorage
  const { user, token } = isAuthenticated();
  const productId = props.match.params.productId;

  const init = async () => {
    //load the product to update based on the product id
    //passed in the url as param and set product state
    try {
      const result = await getProductById(productId);
      if (result.status === 200) {
        setProduct({ ...product, ...result.data });
      } else if (result.status === 401) {
        //Should redirect to login page with this page as an argument so that
        //when the user logs back in he/she is directed back to this page.
      } else {
        status = { status: "error", message: result.status.statusText };
      }
      //setResponseMessage(status);
    } catch (error) {
      console.log("Error getting product inventory:", error);
      status = {
        status: "error",
        message: "Error occured communicating with server. Try again later.",
      };
      //setResponseMessage(status);
    }
  };

  //useEffect hook to fetch all the categories
  useEffect(() => {
    init();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(
      "Updating product. Authorization token: " +
        JSON.stringify(isAuthenticated())
    );
    const productForm = validateProductForm(product);
    loading = true;
    if (!productForm.formIsValid) {
      status = { status: "error", message: JSON.stringify(productForm.errors) };
      loading = false;
      setResponseMessage(status);
      return;
    }

    const form = new FormData();
    form.append("name", name);
    form.append("description", description);
    form.append("slug", slug);
    form.append("categories", chosenCategories);
    //form.append("photo", photo);

    try {
      const result = await updateProduct(productId, user.id, token, form);
      if (result.status === 200) {
        status = {
          status: "success",
          message: `${result.data.data.name} updated successfully.`,
        };
      } else if (result.status === 401) {
        //Should redirect to login page with this page as an argument so that
        //when the user logs back in he/she is directed back to this page.
      } else {
        status = { status: "error", message: result.status.statusText };
      }
      setResponseMessage(status);
    } catch (error) {
      if (error.status === 401) {
        //refresh token or redirect to login page
        console.log("You need to refresh token to continue");
      } else if (error.status === 404) {
        //Should handle page not found by redirecting to page not found page
        //which I am yet to create.
      } else {
        status = {
          status: "error",
          message: "" + error.message + ". Contact system admin for help.",
        };
      }
      setResponseMessage(status);
    }
  };

  const showLoading = () => (
    <div
      className="alert alert-info"
      style={{ display: loading ? "" : "none" }}
    >
      <h4>Loading ........</h4>
    </div>
  );

  const goBack = () => (
    <div className="mt-5">
      <Link className="text-warning" to="/admin/products">
        Go back to products
      </Link>
    </div>
  );

  const createCategorySelectOptions = (categories) => {
    return categories.map((data, id) => {
      return (
        <option value={data._id} key={id}>
          {data.name}
        </option>
      );
    });
  };

  return (
    <AdminBaseLayout
      {...props}
      title={`Update ${product.name}`}
      description={`Hello ${user.firstname}, are you ready to update this product?`}
      className="container"
    >
      <div className="row">
        <div className="col-2">
          <h2 className="title">Update</h2>
          <div>
            {showLoading()}
            <ProductForm
              product={product}
              setProduct={setProduct}
              serverResponse={responseMessage}
              setServerResponse={setResponseMessage}
              handleSubmit={handleSubmit}
            />
            {goBack()}
          </div>
        </div>
      </div>
    </AdminBaseLayout>
  );
};
export default UpdateProduct;
