import axios from "axios";
import { LOCAL_API } from "../config";
import getCommonOptions from "src/helpers/axios/getCommonOptions";

export const createCategory = (formData, user, token) => {
  console.log("Form data: ", formData);
  return axios.post(
    `${LOCAL_API}/admin/category/create/${user.id}`,
    formData,
    getCommonOptions()
  );
};

export const updateCategory = (categoryId, formData, user) => {
  console.log("Form data: ", formData);

  return axios.put(
    `${LOCAL_API}/admin/category/${categoryId}/${user.id}`,
    formData,
    getCommonOptions()
  );
};

export const getCategories = () => {
  return axios.get(`${LOCAL_API}/categories`);
};
