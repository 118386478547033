import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { getCategories } from "src/api/categoriesAPI";

import * as yup from "yup";
import { Formik } from "formik";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Category name is required")
    .max(100, "Max length is 100"),
  slug: yup.string().required("Slug is required").max(100, "Max length is 100"),
});

const CategoryForm = ({
  category,
  setCategory,
  categories,
  handleSubmit,
  buttonName,
  children,
}) => {
  // const [categories, setCategories] = useState([{}]);
  // const [initialValues, setInitialValues] = useState({
  //   name:"",
  //   slug:"",
  //   parentCategory:"",
  //   image: null
  // }

  const fileTypes = ["JPG", "PNG", "GIF"];

  //Destructure form fields
  let { name, slug, parentId, formData } = category;
  const handleChange = (fieldName) => (event) => {
    if (!formData) formData = new FormData();
    if (fieldName === "images") {
      formData.set("images", event.target.files);
    } else {
      setCategory({ ...category, [fieldName]: event.target.value });
      formData.set(fieldName, event.target.value);
    }
  };

  const handleFileChange = (file) => {
    if (file && file.length) {
      if (!formData) formData = new FormData();
      const imgs = [];
      for (let i = 0; i < file.length; i++) {
        imgs.push(file[i]);
        formData.set("images", file[i]);
      }
      setCategory({ ...category, images: imgs });
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await getCategories();
  //     if (res.status === 200) setCategories(res.data);
  //   };
  //   fetchData();
  // }, []);
  return (
    <Box className="mgmt-form">
      <Formik
        onSubmit={handleSubmit}
        initialValues={category}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="name"
                      label="Name"
                      // {...formik.getFieldProps("name")}
                      sx={{
                        mb: 2,
                        mt: 2,
                      }}
                      value={name}
                      onChange={handleChange("name")}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="slug"
                      label="Slug"
                      // {...formik.getFieldProps("name")}
                      sx={{
                        mb: 2,
                      }}
                      value={slug}
                      onChange={handleChange("slug")}
                      error={formik.touched.slug && Boolean(formik.errors.slug)}
                      helperText={formik.touched.slug && formik.errors.slug}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      sx={{
                        mb: 2,
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Parent Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={parentId}
                        label="Parent Category"
                        onChange={handleChange("parentId")}
                      >
                        <MenuItem value="">None</MenuItem>
                        {categories &&
                          categories.length &&
                          categories.map((cat, i) => {
                            return (
                              <MenuItem value={cat.id}>{cat.name}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container={3}>
                  <Grid item xs={12}>
                    <FileUploader
                      handleChange={handleFileChange}
                      label="Add icon"
                      name="images"
                      types={fileTypes}
                      classes="drop_zone drop_area"
                      multiple={true}
                    />
                  </Grid>
                </Grid>

                <button type="submit" className="btn btn-primary">
                  {buttonName ? buttonName : "Add"}
                </button>
                {children}
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CategoryForm;
