import { useContext, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

// import { users } from 'src/_mock/user';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';

import TableNoData from '../table-no-data';
import UserTableRow from '../product-table-row';
import UserTableHead from '../product-table-head';
import TableEmptyRows from '../table-empty-rows';
import UserTableToolbar from '../product-table-toolbar';
import { emptyRows, applyFilter, getComparator } from '../utils';

import { deleteAProduct, getProducts, searchProduct } from "src/api/productAPI";
import { getProductInventory } from 'src/api/productInventoryAPI';
import { enqueueSnackbar } from 'notistack';
import { AuthContext } from 'src/contexts/AuthContextProvider';
import AdminBaseLayout from 'src/components/Layouts/AdminBaseLayout/old-layout';
import { useNavigate } from 'react-router-dom';
import { LOCAL_API } from 'src/config';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function ProductPage(props) {

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {user} = useContext(AuthContext).user
  const navigate = useNavigate()

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = products.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    console.log(name," clicked")
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: products,
    comparator: getComparator(order, orderBy),
    filterName,
  });

    const loadProducts = async () => {
    try {
      const res = await getProductInventory(1,10);
      console.log(res)
      if (res.status === 200) setProducts(res.data.productInventory);
    } catch (error) {
      console.log(error.message);
    }
  };

  const destroyProduct = async (web_id) => {
    try {
      const res = await deleteAProduct(web_id, user.id);
      console.log("Deleting product: ", res.data);
      enqueueSnackbar(res.data.message)
      loadProducts();
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  };

  const notFound = !dataFiltered.length && !!filterName;

  useEffect(() => {
    loadProducts();
  }, [props]);

  return (
    <AdminBaseLayout>
    <Box>
    <Typography variant='h4' mb={5} className='title'>Products</Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        {/* <Typography variant="h4" sx={{color:"var(--primary-color)"}}>Products</Typography> */}
        <Box></Box>
        <Button variant="contained" sx={{background:"var(--primary-color)"}} onClick={()=>{navigate("/admin/product")}} startIcon={<Iconify icon="eva:plus-fill" />}>
          New Product
        </Button>
      </Stack>

      <Card>
        <UserTableToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        <Scrollbar>
          <TableContainer sx={{ overflow: 'unset' }}>
            <Table sx={{ minWidth: 375 }}>
              <UserTableHead
                order={order}
                orderBy={orderBy}
                rowCount={products.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}
                headLabel={[
                  { id: 'name', label: 'Name' },
                  { id: 'salePrice', label: 'sale Price' },
                  { id: 'storePrice', label: 'Store Price' },
                  { id: 'inStock', label: 'In Stock', align: 'center' },
                  { id: 'onSale', label: 'On Sale' },
                  { id: '' },
                ]}
              />
              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <UserTableRow
                      key={row.id}
                      name={row.product.name.substr(0,20)}
                      salePrice={row.salePrice}
                      storePrice={row.storePrice}
                      inStock={row.inStock}
                      avatarUrl={`${LOCAL_API}/product/image/${row.id}`}
                      onSale={row.isOnSale}
                      selected={selected.indexOf(row.id) !== -1}
                      handleClick={(event) => handleClick(event, row.id)}
                    />
                  ))}

                <TableEmptyRows
                  height={77}
                  emptyRows={emptyRows(page, rowsPerPage, products.length)}
                />

                {notFound && <TableNoData query={filterName} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          page={page}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
    </AdminBaseLayout>
  );
}
