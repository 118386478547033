import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';

import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';

import { ShoppingCart } from '@mui/icons-material';
import { Badge, Button } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from 'src/contexts/AuthContextProvider';
import AccountPopover from './common/account-popover';
import NotificationsPopover from './common/notifications-popover';
import Searchbar from './common/searchbar';
import { HEADER } from './config-layout';
import { ShoppingCartContext } from 'src/contexts/ShoppingCartContextProvider';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const {isAuthenticated,user} = useContext(AuthContext) 
  const { cartTotalItems } = useContext(ShoppingCartContext)
  const theme = useTheme();

  const lgUp = useResponsive('up', 'lg');

 

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}
      <Box className="logo" sx={{ textAlign: "center" }}>
            <a href="/">
              <img
                src={process.env.PUBLIC_URL + "/images/logo_dark.png"}
                alt="logo"
                width="125px"
              />
            </a>
          </Box>
      <Searchbar />

      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" alignItems="center" spacing={1}>
        <Box sx={{display:{xs:"none",md:"flex", },fontWeight:"500"}}>
          <Button startIcon={<Iconify icon={"ic:home"} />} LinkComponent={RouterLink} href='/' color="inherit">Home</Button>
          <Button LinkComponent={RouterLink} href='/products' color="inherit">Products</Button>
          <Button color="inherit">About</Button>
          
          {!isAuthenticated && 
            <Button startIcon={<Iconify icon={"ic:lock"} />} LinkComponent={RouterLink} href='/auth/account' color='inherit'> 
              Login
            </Button>
          }
        </Box>
        {cartTotalItems() > 0 && 
            <IconButton LinkComponent={RouterLink} href="/cart">
              <Badge badgeContent={cartTotalItems()} color="error" max={99}>
                <ShoppingCart />
              </Badge>
              </IconButton>}

        {isAuthenticated && 
          <>
            
          
            <NotificationsPopover />
            <AccountPopover user={user?.user} />
          </>
        }
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        // ...(lgUp && {
        //   width: `calc(100% - ${NAV.WIDTH + 1}px)`,
        //   height: HEADER.H_DESKTOP,
        // }),
        background:"var(--secondary-color)",
        color:"var(--primary-text-color)"
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          // px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
