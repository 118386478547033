export const capitalize = (string) => {
  const inputArray = string.split(" ");
  let outputArray = [];
  for (let i = 0; i < inputArray.length; i++) {
    outputArray[i] =
      inputArray[i].charAt(0).toUpperCase() +
      inputArray[i].slice(1).toLowerCase();
  }
  return outputArray.join(" ");
};
