import { useCallback, useContext, useEffect, useState } from "react";

import "braintree-web";
import DropIn from "braintree-web-drop-in-react";
import { Link } from "react-router-dom";
import { getBraintreeClientToken, processPayment } from "src/api/brainTreeAPI";
import { createOrder } from "src/api/OrderAPI";
import { getShippingAddresses } from "src/api/shippingAPI";
import { cartTotal, emptyCart } from "src/helpers/cartHelpers";
import ShippingAddressForm from "./ShippingAddressForm";
import { AuthContext } from "src/contexts/AuthContextProvider";
// import {
//   getBraintreeClientToken,
//   processPayment,
//   createOrder,
// } from "../api/brainTreeAPI";

const Checkout = ({ cart, setRun = (f) => f, run = undefined }) => {
  const [data, setData] = useState({
    success: false,
    clientToken: null,
    error: "",
    instance: {},
    address: "",
    loading: false,
  });

  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [shippindAddress, setShippingAddress] = useState({ id: "" });
  const { isAuthenticated, user } = useContext(AuthContext);

  const userId = isAuthenticated && user?.user?.id;

  const [showModal, setShowModal] = useState(false);

  const handleChange = (name) => (event) => {
    console.log(
      "name passed to handleChange",
      name,
      "value ",
      event.target.value
    );
    setShippingAddress({ ...shippindAddress, [name]: event.target.value });
  };
  const showCheckout = () =>
    isAuthenticated ? (
      <div>{showDropIn()}</div>
    ) : (
      <Link to="/auth/account" className="btn checkout-btn">
        Sign In or Create Account to Checkout
      </Link>
    );

  const buy = (e) => {
    e.preventDefault();
    setData({ ...data, loading: true });
    //Get delivery address from state to avoid (data) name collision
    //let deliveryAddress = data.address;
    //send the nonce to your server
    //nonce = data.instance.requestPaymentMethod()
    let nonce;
    data.instance
      .requestPaymentMethod()
      .then((data) => {
        nonce = data.nonce;
        //once you have nonce (card type, card number) send nonce as 'paymentMethodNonce'
        //and also total to be charged ;
        //console.log('send nonce and total to process: ',nonce,getTotal(cart))
        const paymentData = {
          paymentMethodNonce: nonce,
          amount: cartTotal(cart),
        };

        processPayment(userId, paymentData)
          .then(async (response) => {
            console.log("Process payment response ", response);
            setData({ ...data, success: response.success });
            //create order
            const createOrderData = {
              products: cart,
              transaction: response.transaction,
              amount: response.transaction.amount,
              shippingAddressId: shippingAddresses[0].id,
              statusId: 1,
            };
            //console.log("Sending this to db: ", createOrderData);

            try {
              await createOrder(userId, createOrderData);
              //  empty cart
              emptyCart(() => {
                setData({ ...data, loading: false, success: true });
                setRun(!run);
              });
            } catch (error) {
              console.log("Error response: ", error?.response.data.message);
              setData({
                ...data,
                error: error.response?.data?.message,
                success: false,
              });
            }
          })
          .catch((e) => {
            console.log("process payment error: ", e);
            setData({ ...data, error: e.message, loading: false });
          });
      })
      .catch((e) => {
        //console.log('DropIn error: ',e);
        setData({ ...data, error: e.message, loading: false });
      });
  };
  const showShipping = () => {
    if (isAuthenticated) {
      if (shippingAddresses.length < 1) {
        return (
          <div className="shipping-address-form-container">
            <h3 className="title">Add Shipping Address</h3>
            <ShippingAddressForm />
          </div>
        );
      } else {
        return (
          <div>
            <h3 className="small-title">
              Choose shipping address{" "}
              <button className="btn" onClick={() => setShowModal(!showModal)}>
                + Add Address
              </button>
            </h3>

            <table className="responsive-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Names</th>
                  <th>Mobile</th>
                  <th>Address</th>
                  <th>Region</th>
                </tr>
              </thead>
              <tbody>
                {shippingAddresses.map((shipAddr, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <input
                          name="address"
                          type={"radio"}
                          value={shipAddr.id}
                          onChange={handleChange("id")}
                        />
                      </td>

                      <td data-source="Names">{shipAddr.fullnames}</td>
                      <td data-source="Mobile">{shipAddr.mobile}</td>
                      <td data-source="Address">{shipAddr.address}</td>
                      <td data-source="Region">
                        {shipAddr?.ward?.name}, {shipAddr?.constituency?.name},{" "}
                        {shipAddr?.county?.name}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      }
    }
  };

  const showDropIn = () => (
    <div
      onBlur={() => {
        setData({ ...data, error: "" });
      }}
    >
      {data.clientToken !== null &&
      cart.length > 0 &&
      shippingAddresses.length > 0 ? (
        <div>
          <DropIn
            options={{
              authorization: data.clientToken,
              paypal: {
                flow: "vault",
              },
            }}
            onInstance={(instance) => (data.instance = instance)}
          />

          <Link to={"/#"} onClick={buy} className="btn">
            Checkout
          </Link>
        </div>
      ) : null}
    </div>
  );

  const loadUserShippingAddresses = useCallback(async () => {
    try {
      const response = await getShippingAddresses(userId);
      setShippingAddresses(response.data);
      console.log("Shipping addresses: \n ", response.data);
    } catch (error) {
      console.log(error.message);
    }
  }, [userId]);

  const getBraintreeToken = useCallback(async (userId) => {
    try {
      const res = await getBraintreeClientToken(userId);
      setData({ ...data, clientToken: res.data.clientToken });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBraintreeToken(userId);
  }, [getBraintreeToken, userId]);
  useEffect(() => {
    loadUserShippingAddresses();
  }, [loadUserShippingAddresses]);
  console.log("Found shipping addresses: ", shippingAddresses);

  return (
    <>
      <div className="checkout-component">
        <div
          className="alert alert-info myToast"
          style={{ display: data.success ? "flex" : "none" }}
        >
          <h5>Thanks! Your payment was successful.</h5>
        </div>
        <div
          className="alert alert-danger myToast"
          style={{ display: data.error ? "flex" : "none" }}
        >
          {data.error}
        </div>
        <div
          className="alert alert-info myToast"
          style={{ display: data.loading ? "" : "none" }}
        >
          <h2>Loading.....</h2>
        </div>
        {showShipping()}
        {showCheckout()}

        {showModal && isAuthenticated && (
          <div className="add-shipping-modal">
            <div
              className="modal-close-btn"
              onClick={() => setShowModal(false)}
            >
              X
            </div>
            <div className="transbox">
              <h3 className="small-title white">Add new address</h3>
              <ShippingAddressForm />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Checkout;
