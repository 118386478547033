import { Box } from "@mui/material";
import AppHeader from "./AppHeader";
import Footer from "./footer";
import SideMenu from "./SideMenu";
const BaseLayout = (props) => {
  // const [mobileOpen, setMobileOpen] = useState(false);
  return (
    <Box>
      <Box sx={{ display: (theme) => (props.categories ? "flex" : "block") }}>
        <Box>
          <AppHeader />

          {props.categories && (
            <SideMenu
              mobileOpen={props.mobileOpen}
              setMobileOpen={props.setMobileOpen}
              categories={props.categories}
              handleFilters={props.handleFilters}
            />
          )}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            // padding: (theme) => theme.spacing(3),
            mt: 10,
          }}
        >
          <Box sx={{ padding: (theme) => theme.spacing(3) }}>
            {props.children}
          </Box>
          {/* This footer displays when there is a side bar and it starts where side bar ends */}
          {props.categories && (
            <Footer
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
                margin: 0,
              }}
            />
          )}
        </Box>
      </Box>
      {/* This footer is shown when there is no side bar */}
      {!props.categories && <Footer />}
    </Box>
  );
};

export default BaseLayout;
