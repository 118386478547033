import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LOCAL_API } from "../../config";
const Card = ({ productInventory }) => {
  return (
    <div className="card">
      <Link to={`/product/${productInventory.id}`}>
        <img src={`${LOCAL_API}/product/image/${productInventory.id}`} alt="" />
        <h4>
          {productInventory.product.name.substr(0, 15)}
          {productInventory.product.name.length >= 50 ? "..." : ""}
        </h4>
        <div className="rating">
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star-half-o"></i>
          <i className="fa fa-star-o"></i>
        </div>
        <p>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "KSh",
          }).format(productInventory?.storePrice)}
        </p>
      </Link>
    </div>
  );
};

export default Card;
