import { useEffect } from "react";

const Inbox = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="small-container">
        <div className="sub-container">
          <div className="sub-container-content">
            <h2 className="title">Inbox</h2>
          </div>
        </div>
      </div>
    </>
  );
};
export default Inbox;
