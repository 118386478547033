import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { FileUploader } from "react-drag-drop-files";

/** 
  This component is used to add a product inventory. This means that we must provide
  The product we are creating the inventory for as an argument. I also use forwardRef so 
  I can reference the form element in this components from the AddProduct component.
  The other parameters passed are state variables from parent component which much include 
      
    *productInventory     - This is a state object that will hold the form fields values
    *setProductInventory  - Used to mutate the productInventory state variables
    *files                - Holds the files uploaded by FileUploaderComponent
    *setFiles             - Used to mutate files state variable
    *products             - This variable holds all the products in the system so the user 
                            can select from a drop down which product to add inventory for. 
                            Currently not in use for now since I am using steps mechanism to add product
    *product              - Contains the product object which the inventory is being created for.
    *productTypes         - Contains an array of ProductTypes objects that used to create a drop-down so the user can
                            Select the product type. 
    *brands               - Same as product types but for product Brands  
    *fileTypes            - Types of files allowed for upload as product inventory Image
    *action               - Tells the component if it's being used to update or create the product inventory            
  
*/
const ProductInventoryForm = React.forwardRef(
  (
    {
      productInventory,
      setProductInventory,
      files,
      setFiles,
      products,
      product,
      productTypes,
      brands,
      fileTypes,
      // handleSubmit,
      action = "create",
      //children,
    },
    ref
  ) => {
    //Destructure productInventory so I can use the fields independently
    let {
      sku,
      upc,
      productType,
      brand,
      media,
      rrPrice,
      storePrice,
      salePrice,
      formData,
      inStock,
    } = productInventory;

    //This method is called everytime you select images using the FileUploader component.
    const handleImageUpload = (files) => {
      setFiles(files);
      if (!formData) formData = new FormData();
      //let photos = [];
      for (let i = 0; i < files.length; i++) {
        media.push(files[i]);
        formData.append("media", files[i]);
      }
      //setProductInventory({ ...productInventory, media: photos });
      console.log("Image upload handle.");
      console.log(files);
    };

    //Set values in product state whenever a form field changes value
    const handleChange = (name) => (event) => {
      if (!formData) formData = new FormData();
      let value = null;
      if (name === "media") {
        value = files;
        //setFiles(value);
      } else {
        value = event.target.value;
      }
      //const value = name === "photo" ? event.target.files[0] : event.target.value;

      //This line is bringing an error of undefined when I go to update product inventory but is
      //required in add product inventory. Trying to find what to do with it but for testing
      //I am just commenting when I go to update page.
      formData.set(name, value);
      setProductInventory({ ...productInventory, [name]: value });
    };

    return (
      <div className="tab" ref={ref}>
        <h2 className="title">{product.name} inventory details</h2>
        <Formik
          // onSubmit={handleSubmit}
          initialValues={product}
          enableReinitialize
          // validationSchema={validationSchema}
        >
          {(formik) => (
            <form
              // onSubmit={handleSubmit}
              //encType="multipart/form-data"
              id="productInventoryForm"
            >
              {/* <div className="form-group">
                <label>Product</label>
            <input type="text"  value={product.name}/>
            <select
              onChange={handleChange("product")}
              className="form-control"
              aria-describedby="nameHelp"
              value={
                productInventory.productId
                  ? productInventory.productId
                  : "default"
              }
            >
              <option defaultValue={"default"}>Select Product</option>
              {products &&
                products.length &&
                products.map((prod, index) => (
                  <option
                    key={index}
                    value={prod.id}
                    // selected={
                    //   productInventory.productId === prod.id ? true : false
                    // }
                  >
                    {prod.name}
                  </option>
                ))}
            </select>
              </div> */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="sku"
                    label="SKU (Store Keeping Unit)"
                    value={sku}
                    onChange={handleChange("sku")}
                    error={formik.touched.sku && Boolean(formik.errors.sku)}
                    helperText={formik.touched.sku && formik.errors.sku}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="upc"
                    label="UPC (Universal Product Code)"
                    value={upc}
                    onChange={handleChange("upc")}
                    error={formik.touched.upc && Boolean(formik.errors.upc)}
                    helperText={formik.touched.upc && formik.errors.upc}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="product-select-label">
                      Product Type
                    </InputLabel>
                    <Select
                      labelId="product-select-label"
                      id="product-select"
                      size="small"
                      value={productType}
                      label="Product Type"
                      onChange={handleChange("productType")}
                    >
                      {productTypes &&
                        productTypes.length &&
                        productTypes.map((pType, index) => (
                          <MenuItem key={index} value={`${pType.id}`}>
                            {pType.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="brand-select-label">Brand</InputLabel>
                    <Select
                      labelId="brand-select-label"
                      id="brand-select"
                      size="small"
                      value={brand}
                      label="Product Type"
                      onChange={handleChange("brand")}
                    >
                      {brands &&
                        brands.length &&
                        brands.map((brnd, index) => (
                          <MenuItem key={index} value={brnd.id}>
                            {brnd.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="in_stock"
                    label="In Stock"
                    value={inStock}
                    onChange={handleChange("inStock")}
                    error={
                      formik.touched.in_stock && Boolean(formik.errors.in_stock)
                    }
                    helperText={
                      formik.touched.in_stock && formik.errors.in_stock
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="rr_price"
                    label="Recomended retail Price"
                    value={rrPrice}
                    onChange={handleChange("rrPrice")}
                    error={
                      formik.touched.rr_price && Boolean(formik.errors.rr_price)
                    }
                    helperText={
                      formik.touched.rr_price && formik.errors.rr_price
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="store_price"
                    label="Store Price"
                    value={storePrice}
                    onChange={handleChange("storePrice")}
                    error={
                      formik.touched.store_price &&
                      Boolean(formik.errors.store_price)
                    }
                    helperText={
                      formik.touched.store_price && formik.errors.store_price
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="sale_price"
                    label="Sale Price"
                    value={salePrice}
                    onChange={handleChange("salePrice")}
                    error={
                      formik.touched.sale_price &&
                      Boolean(formik.errors.sale_price)
                    }
                    helperText={
                      formik.touched.sale_price && formik.errors.sale_price
                    }
                  />
                </Grid>

                {action === "create" ? (
                  <Grid item xs={12}>
                    <FileUploader
                      handleChange={handleImageUpload}
                      onChange={handleChange("media")}
                      label="Add product images"
                      name="media"
                      types={fileTypes}
                      multiple={true}
                      classes="drop_zone drop_area"
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    );
  }
);

export default ProductInventoryForm;
