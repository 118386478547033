import axios from "axios";
import { LOCAL_API } from "../config";
import getCommonOptions from "src/helpers/axios/getCommonOptions";
export const getProductTypeAttributes = (productTypeId) => {
  return axios.get(`${LOCAL_API}/product-type/${productTypeId}/attributes/`);
};
export const createProductType = (productType, user, token) => {
  return axios.post(
    `${LOCAL_API}/admin/product-type/create/${user.id}`,
    productType,
    getCommonOptions()
  );
  // return fetch(`${LOCAL_API}/admin/product-type/create/${user.id}`, {
  //   method: "POST",
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     //,'Content-Type': 'multipart/form-data'
  //   },
  //   body: productType,
  // })
  //   .then((data) => {
  //     return data.json();
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
};

export const getProductTypes = () => {
  return axios.get(`${LOCAL_API}/product-types`);
};
