import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import Signin from "src/user/Signin";
// import Signup from "src/user/Signup";
import Account from "src/pages/Auth";
import Cart from "src/pages/Cart";
import Home from "src/pages/Home";
import Product from "src/pages/Products/Product";
import { ProductsView } from "src/pages/Products/view";
import Brands from "src/pages/admin/Brands";
import BrandDetails from "src/pages/admin/Brands/BrandDetails";
import Categories from "src/pages/admin/Categories";
import CategoryDetails from "src/pages/admin/Categories/CategoryDetails";
import AdminDashboard from "src/pages/admin/Dashboard";
import AdminOrders from "src/pages/admin/Orders";
import ManageProductInventory from "src/pages/admin/ProductInventory";
import AddProductInventory from "src/pages/admin/ProductInventory/AddProductInventory";
import UpdateProductInventory from "src/pages/admin/ProductInventory/UpdateProductInventory";
import ProductTypes from "src/pages/admin/ProductTypes";
import ProductTypeDetails from "src/pages/admin/ProductTypes/ProductTypeDetails";
import ManageProducts from "src/pages/admin/Products";
import ProductDetails from "src/pages/admin/Products/ProductDetails";
import UpdateProduct from "src/pages/admin/Products/UpdateProduct";
import Search from "src/pages/core/Search";
import Inbox from "src/pages/user/Inbox";
import Orders from "src/pages/user/Orders";
import Profile from "src/pages/user/Profile";
import Saved from "src/pages/user/Saved";
import AdminRoute from "src/services/auth/AdminRoute";
import PrivateRoute from "src/services/auth/PrivateRoute";
import RequireNoAuth from "./services/auth/RequireNoAuth";
import BaseLayout from "./components/Layouts/BaseLayout";
import Logout from "./pages/Logout";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Public routes accessible to anyone */}
        <Route element={<BaseLayout />}>
          <Route path="/" exact element={<Home />} />
          {/* <Route path="/shop" exact element={<ProductsView />} /> */}
          <Route path="/products" exact element={<ProductsView />} />
          <Route path="/search" exact element={<Search />} />
          <Route path="/cart" exact element={<Cart />} />
          <Route path="/product/:productId" exact element={<Product />} />
          <Route element={<RequireNoAuth />}>
            <Route path="/auth/account" exact element={<Account />} />
          </Route>
          {/* logged in customer routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/user/profile/:userId" exact element={<Profile />} />
            <Route path="/user/inbox" exact element={<Inbox />} />
            <Route path="/user/orders" exact element={<Orders />} />
            <Route path="/user/saved" exact element={<Saved />} />
            <Route path="/auth/account/logout" exact element={<Logout />} />
          </Route>
        </Route>
        {/* Administrator's administration routes */}
        <Route element={<AdminRoute />}>
          <Route path="/admin/dashboard" exact element={<AdminDashboard />} />
          <Route path="/admin/orders" exact element={<AdminOrders />} />

          {/* Routes to add and manage category */}
          <Route path="/admin/category" exact element={<CategoryDetails />} />
          <Route
            path="/admin/category/:id"
            exact
            element={<CategoryDetails />}
          />

          <Route path="/admin/categories" exact element={<Categories />} />

          {/* Routes to add and manage brand */}
          <Route path="/admin/brand" exact element={<BrandDetails />} />
          <Route path="/admin/brand/:id" exact element={<BrandDetails />} />
          <Route path="/admin/brands" exact element={<Brands />} />

          {/* Routes to add and manage product type */}
          <Route
            path="/admin/product-type"
            exact
            element={<ProductTypeDetails />}
          />
          <Route
            path="/admin/product-type/:id"
            exact
            element={<ProductTypeDetails />}
          />
          <Route path="/admin/product-types" exact element={<ProductTypes />} />

          {/* Routes to add and manage product */}
          <Route path="/admin/product" exact element={<ProductDetails />} />
          <Route path="/admin/product/:id" exact element={<UpdateProduct />} />

          <Route path="/admin/products" exact element={<ManageProducts />} />

          <Route
            path="/admin/product/:productId"
            exact
            element={<UpdateProduct />}
          />
          <Route
            path="/admin/manage/add/product-inventory"
            exact
            element={<AddProductInventory />}
          />
          <Route
            path="/admin/manage/product-inventory"
            exact
            element={<ManageProductInventory />}
          />
          <Route
            path="/admin/manage/update/product-inventory/:productInventoryId"
            exact
            element={<UpdateProductInventory />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
