import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import React from "react";

import CategoryIcon from "@mui/icons-material/Category";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { NavLink } from "react-router-dom";

import {
  Dashboard,
  ListAltRounded,
  SubscriptionsOutlined,
} from "@mui/icons-material";
import { GlobalStyles } from "@mui/material";
import { Box } from "@mui/system";

import { AuthContext } from "src/contexts/AuthContextProvider";

const drawerWidth = 240;

const listItems = [
  {
    key: "dashboard",
    to: "/admin/dashboard",
    name: "Dashboard",
    icon: <Dashboard />,
  },
  {
    key: "categories",
    to: "/admin/categories",
    name: "Categories",
    icon: <CategoryIcon />,
  },
  {
    key: "brands",
    to: "/admin/brands",
    name: "Brands",
    icon: <SubscriptionsOutlined />,
  },

  {
    key: "product-types",
    to: "/admin/product-types",
    name: "Product Types",
    icon: <ListAltRounded />,
  },
  {
    key: "products",
    to: "/admin/productS",
    name: "Products",
    icon: <ListAltRounded />,
  },
];

const SidebarGlobalStyles = () => {
  // const theme = useTheme();
  return (
    <GlobalStyles
      styles={{
        ".sidebar-nav-item": {
          color: "unset",
          textDecoration: "none",
        },
        ".sidebar-nav-item-active": {
          textDecoration: "none",
          color: "#ff523b;",
          "& .MuiSvgIcon-root": {
            color: " #ff523b;",
          },
          "& .MuiTypography-root": {
            fontWeight: 500,
            color: "#ff523b",
          },
        },
      }}
    />
  );
};
const SidebarGlobalStylesMemo = React.memo(SidebarGlobalStyles);

export function SideMenu(props) {
  const { user } = React.useContext(AuthContext);

  const { mobileOpen, setMobileOpen } = props;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <Toolbar />
      <Divider />
      <List>
        {listItems.map((li) => {
          return (
            <NavLink
              end={li.to === "/" ? true : false}
              className={(props) => {
                return `${
                  props.isActive
                    ? "sidebar-nav-item-active"
                    : "sidebar-nav-item"
                }`;
              }}
              to={li.to}
              key={li.key}
            >
              <ListItem button>
                <ListItemIcon>{li.icon}</ListItemIcon>
                <ListItemText primary={li.name} />
              </ListItem>
            </NavLink>
          );
        })}
      </List>
      <Divider />
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <SidebarGlobalStylesMemo />
      {/* This drawer is shown on small and extra small screens and not from medium screen size */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            color: "#563434",
          },
          ".sidebar-nav-item": {
            "& .MuiSvgIcon-root": {
              color: " #563434;",
            },
          },
        }}
      >
        {drawer}
      </Drawer>
      {/* This drawer is shown only on medium  screens upwards, small and xs is not shown. */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            color: "#563434",
          },
          ".sidebar-nav-item": {
            "& .MuiSvgIcon-root": {
              color: " #563434;",
            },
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

SideMenu.propTypes = {
  mobileOpen: PropTypes.bool,
  setMobileOpen: PropTypes.func.isRequired,
};

export default SideMenu;
