import { Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import { forwardRef, useState } from "react";
import useRequestAuth from "src/hooks/useRequestAuth";
import * as yup from "yup";

const validationSchema = yup.object({
  firstname: yup
    .string()
    .required("Firstname is required")
    .max(100, "Max length is 100"),
  lastname: yup
    .string()
    .required("Lastname is required")
    .max(100, "Max length is 100"),
  mobile: yup
    .string()
    .required("Phone number is required")
    .max(20, "Max length is 20"),
  email: yup
    .string()
    .email("Please input a vaild emaila address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .max(100, "Max length is 100 characters"),
});

const RegisterForm = forwardRef(({ parentProps, ...others }, ref) => {
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
  });
  const { register } = useRequestAuth();
  const { firstname, lastname, email, mobile, password, confirmPassword } =
    values;
  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      enqueueSnackbar("Password and confirm password do not match.", {
        variant: "error",
      });
      return;
    }
    register({ firstname, lastname, email, mobile, password });
  };

  return (
    <div className="register-form-container">
      <Formik
        onSubmit={handleSubmit}
        initialValues={values}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => (
          <form
            action=""
            id="register-form"
            onSubmit={formik.handleSubmit}
            ref={ref}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="firstname"
                  label="Firstname"
                  // {...formik.getFieldProps("firstname")}
                  value={values.firstname}
                  onChange={handleChange("firstname")}
                  error={
                    formik.touched.firstname && Boolean(formik.errors.firstname)
                  }
                  helperText={
                    formik.touched.firstname && formik.errors.firstname
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="lastname"
                  label="Lastname"
                  // {...formik.getFieldProps("lastname")}
                  value={values.lastname}
                  onChange={handleChange("lastname")}
                  error={
                    formik.touched.lastname && Boolean(formik.errors.lastname)
                  }
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange("email")}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="mobile"
                  label="Mobile phone number"
                  value={values.mobile}
                  onChange={handleChange("mobile")}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="password"
                  label="Password"
                  type="password"
                  value={values.password}
                  onChange={handleChange("password")}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="confirm_password"
                  label="Confirm Password"
                  type="password"
                  value={values.confirm_password}
                  onChange={handleChange("confirmPassword")}
                  error={
                    formik.touched.confirm_password &&
                    Boolean(formik.errors.confirm_password)
                  }
                  helperText={
                    formik.touched.confirm_password &&
                    formik.errors.confirm_password
                  }
                />
              </Grid>
            </Grid>

            <button className="btn">Register</button>
          </form>
        )}
      </Formik>
    </div>
  );
});

export default RegisterForm;
