import { Box, Paper } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getCategories } from "src/api/apiAdmin";
import { createCategory, updateCategory } from "src/api/categoriesAPI";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";
import { AuthContext } from "src/contexts/AuthContextProvider";
import { showServerResponseMessage } from "src/helpers/ShowMessageHelper";
import CategoryForm from "./CategoryForm";
const CategoryDetails = (props) => {
  const [category, setCategory] = useState({
    name: "",
    slug: "",
    images: [],
    parentId: "",
    formData: new FormData(),
  });
  const [categories, setCategories] = useState([]);
  const params = useParams();

  //Destructure user and toker from localStorage
  const { user, token } = useContext(AuthContext);
  //Destructure form fields
  let { images, formData } = category;

  const getAllCategories = useCallback(async () => {
    try {
      const res = await getCategories();
      setCategories(res.data);
    } catch (error) {
      // console.log(error);
    }
  }, [setCategories]);

  useEffect(() => {
    getAllCategories();
  }, [getAllCategories]);

  const showUploadImage = () =>
    images &&
    images.length > 0 && (
      <div className="row uploaded-images">
        {images.map((image, i) => (
          <div className="co" key={i}>
            <img src={URL.createObjectURL(image)} alt={image.name} />
          </div>
        ))}
      </div>
    );
  const handleSubmit = async () => {
    console.log("Data posted in category", Object.fromEntries(formData));
    //Check if category id is set, if it is set, then we want to update.
    if (params.id) {
      setCategory({ ...category, updatedAt: "" + Date.toLocaleString() });
      try {
        const res = await updateCategory(
          category.id,
          category,
          user?.user,
          token
        );
        if (res.status === 200) {
          const res = await getCategories();
          console.log("Updated successfully. ", res.data);
          enqueueSnackbar("Category updated successfully", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Could not update category", {
            variant: "error",
          });
        }
      } catch (error) {
        console.log("Could not update category: ", error);
        enqueueSnackbar("Could not update category", {
          variant: "error",
        });
      }
      return;
    }

    try {
      const res = await createCategory(formData, user.user);
      getAllCategories();
      console.log(res.data);
      if (res.status === 200) {
        setCategory({
          name: "",
          slug: "",
          images: [],
          parentId: "",
          formData: new FormData(),
        });
      }
      // setServerResponse(res.data);
      enqueueSnackbar(res.data.message, {
        variant: "success",
      });
    } catch (error) {
      if (error.response.status === 401) {
        console.log("Your session has expired: ", error.message);
        enqueueSnackbar("Server session has expired, re-login", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          error.response.statusText +
            ". Try again later or contact system admin if problem persist.",
          {
            variant: "error",
          }
        );
      }
      console.log(error.response);
    }
  };
  return (
    <AdminBaseLayout>
      <Paper className="small-container">
        <h2 className="title">{} Category</h2>

        <Box>
          <CategoryForm
            category={category}
            setCategory={setCategory}
            categories={categories}
            handleSubmit={handleSubmit}
            buttonName="Add Category"
          >
            <div>
              <Link to="/admin/categories">Go to categories</Link>
            </div>
          </CategoryForm>

          {showUploadImage()}
        </Box>
      </Paper>
    </AdminBaseLayout>
  );
};
export default CategoryDetails;
