import axios from "axios";
import { LOCAL_API } from "../config";
import getCommonOptions from "src/helpers/axios/getCommonOptions";

export const addBrand = (brand, user) => {
  return axios.post(
    `${LOCAL_API}/admin/brand/add/${user.id}`,
    brand,
    getCommonOptions()
  );
};

export const getBrands = () => {
  return axios.get(`${LOCAL_API}/brands`);
};
