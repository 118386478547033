import { Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import { forwardRef, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import useRequestAuth from "src/hooks/useRequestAuth";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Please input a vaild email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .max(100, "Max length is 100 characters"),
});
const LoginForm = forwardRef((props, ref) => {
  const [values, setValues] = useState({ email: "", password: "" });

  const { login } = useRequestAuth();
  const handleSubmit = () => {
    login(values);
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={values}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {(formik) => (
        <form ref={ref} id="login-form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                label="Email"
                // {...formik.getFieldProps("firstname")}
                value={values.firstname}
                onChange={handleChange("email")}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="password"
                label="Password"
                type="password"
                // {...formik.getFieldProps("firstname")}
                value={values.firstname}
                onChange={handleChange("password")}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
          </Grid>

          <button className="btn">Log in</button>
          <Link to="">Forgot password</Link>
        </form>
      )}
    </Formik>
  );
});

export default LoginForm;
