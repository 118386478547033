import { useCallback, useEffect, useRef, useState } from 'react';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

// import { products } from 'src/_mock/products';
import { getCategories } from "src/api/categoriesAPI";
import {
  getFilteredProductInventory,
  getProductInventory,
} from "src/api/productInventoryAPI";

import { Box, Pagination, Toolbar } from '@mui/material';
import ProductCartWidget from '../../../components/reusable/product-cart-widget';
import ProductCard from '../product-card';
import ProductFilters from '../product-filters';
import ProductSort from '../product-sort';
// import BaseLayout from 'src/components/Layouts/BaseLayout';

// ----------------------------------------------------------------------
import "src/assets/css/style.css";
export default function ProductsView() {
  const [productFilters, setProductFilters] = useState({
    filters: { category: [], price: [], searchText: "" },
  });
  const [categories, setCategories] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(0);
  const [showingProductsFrom, setShowingProductsFrom] = useState(1);
  const [showingProductsUpto, setShowingProductsUpto] = useState(1);
  const [productInventory, setProductInventory] = useState([]);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const searchInput = useRef();
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handlePageChange = (event,value)=>{
    console.log("New page ", value)
    setPage(value)
  }
  const loadProductInventory = async (page, limit) => {
    // spinner.current.style.visibility = "visible";
    setPage(page);
    setLimit(limit);
    setShowingProductsFrom(page * limit - limit + 1);

    try {
      const res = await getProductInventory(page, limit);
      //console.log(res.data);
      setProductInventory(res.data.productInventory);
      setSize(res.data.rowsCount);
      setShowingProductsUpto(
        page * limit < res.data.rowsCount ? page * limit : res.data.rowsCount
      );
      // spinner.current.style.visibility = "hidden";
    } catch (error) {
      console.log(error.message);
      // spinner.current.style.visibility = "hidden";
    }
  };
  const handleCategoryFilterChange = (event)=>{
    let prodFilters = productFilters;
    const selected = prodFilters.filters.category;
    if(event.target.checked === true){
      const isSelected = selected.indexOf(parseInt(event.target.value))
      if(isSelected === -1){
        prodFilters = {...prodFilters,filters:{...prodFilters.filters, category:selected}}
        selected.push(parseInt(event.target.value))
        setProductFilters(prodFilters)
        loadFilteredResults(prodFilters,page,limit)
      }
    }
    if(event.target.checked === false){
      const isSelected = selected.indexOf(parseInt(event.target.value))
      if(isSelected !== -1){
        prodFilters = {...prodFilters,filters:{...prodFilters.filters, category: selected}}
        selected.splice(isSelected,1)
        setProductFilters(prodFilters)
        loadFilteredResults(prodFilters,page,limit)
      }
    }
  }
  const loadFilteredResults = useCallback(async (newFilters, page, limit) => {
    // spinner.current.style.visibility = "visible";
    console.log("loadFilteredResults called: ",productFilters.filters)
    setPage(page);
    setLimit(limit);
    try {
      const res = await getFilteredProductInventory(
        page,
        limit,
        newFilters
      );
      setProductInventory(res.data.productInventory);
      setShowingProductsFrom(page * limit - limit + 1);
      setSize(res.data.rowsCount);
      setShowingProductsUpto(
        page * limit < res.data.rowsCount ? page * limit : res.data.rowsCount
      );
      console.log(res.data)
      // spinner.current.style.visibility = "hidden";
    } catch (error) {
      console.log("Error found gettingFilteredResult: ", error.message);
      // spinner.current.style.visibility = "hidden";
    }
  },[]);
  useEffect(() => {
    const init = async () => {
      //loadFilteredResults(page, limit, myFilters.filters);
      //loadProductInventorySize();
      loadProductInventory(page, limit);
      //Load categories and set formData in product state
      try {
        const res = await getCategories();
        setCategories(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    init();
    // searchInput.current.addEventListener("keypress", (e) => {
    //   if (e.key === "Enter") {
    //     // searchProduct(searchInput.current.value);
    //   }
    // });
    window.scrollTo(0, 0);
  }, [limit,page]);
  useEffect(()=>{
    loadFilteredResults(productFilters,1,10)
    window.scrollTo(0, 0);
  },[productFilters, loadFilteredResults])
  return (
    // <BaseLayout>
    <Container>
      {/* <Typography variant="h4" sx={{ mb: 5 }}>
        Products
      </Typography> */}
      <h2 className='title'>Products</h2>

      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap-reverse"
        justifyContent="flex-end"
        sx={{ mb: 5 }}
      >
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <ProductFilters
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            categories={categories}
            handleChange = {handleCategoryFilterChange}
            filters={productFilters.filters.category}
          />

          <ProductSort />
        </Stack>
      </Stack>

      <Grid container spacing={3}>
        {productInventory.map((product,idx) => (
          <Grid  key={idx} xs={12} sm={6} md={3} item="true">
            <ProductCard product={product} />
          </Grid>
        ))}
        <Grid item="true" xs={12}>
          <Toolbar />
          <ProductCartWidget />
        </Grid>
        <Grid item="true">
        <Pagination count={Math.ceil(size/limit)}  page={page} onChange={handlePageChange} color='primary'
                sx={{display:"block", color:"green"
                }}
            />
        </Grid>
      </Grid>

      <Box spacing={1} direction="row" justifyContent="flex-end"   >
            <Box sx={{position:"relativ",display:"inline-block"}} >
             

              {/* <Box sx={{position: "absolute",top:"5px", right:"5px",
                  zIndex:1000}}> */}
            
           {/* </Box> */}
              
              </Box>
              
          </Box>
      
    </Container>
    
  );
}
