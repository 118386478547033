import axios from "../services/interceptors/api.interceptor";

import { LOCAL_API } from "../config";
import getCommonOptions from "src/helpers/axios/getCommonOptions";

export const postAddAddress = (address, userId, token) => {
  return axios.post(
    LOCAL_API + "/shipping/add-address/" + userId,
    address,
    getCommonOptions()
  );
};
export const getShippingAddresses = (userId, token) => {
  return axios.get("/shipping/addresses/" + userId, getCommonOptions());
};
export const getCounties = () => {
  return axios.get(LOCAL_API + "/shipping/get-counties");
};

export const getConstituenciesForCounty = (countyId) => {
  return axios.get(
    LOCAL_API + "/shipping/get-constituencies-for-county/" + countyId
  );
};

export const getWardsForConstituency = (constituencyId) => {
  return axios.get(
    LOCAL_API + "/shipping/get-wards-for-constituency/" + constituencyId
  );
};
