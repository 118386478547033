import { Box, Paper } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addBrand, getBrands } from "src/api/brandAPI";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";
import { LOCAL_API } from "src/config";
import { AuthContext } from "src/contexts/AuthContextProvider";
import { showServerResponseMessage } from "src/helpers/ShowMessageHelper";
import BrandForm from "./BrandForm";
import { enqueueSnackbar } from "notistack";

const Brands = (props) => {
  const navigate = useNavigate();
  //brand object to send to server
  const [brand, setBrand] = useState({
    name: "",
    images: [],
    formData: new FormData(),
  });

  //Stores brands from the database;
  const [brands, setBrands] = useState([]);

  //Destructure user and toker from AuthContext
  const { user, token } = useContext(AuthContext);

  //State for messages from the server.
  const [serverResponse, setServerResponse] = useState({});

  const { formData } = brand;

  const submitForm = (e) => {
    e.preventDefault();
    console.log("submiting form. Brand value is: ", formData);
    addBrand(formData, user?.user, token).then((data) => {
      if (data.error) {
        console.log("Arror occured adding brand: ", data.error);
        setServerResponse(data.error);
        enqueueSnackbar(data.error, { variant: "error" });
      } else {
        console.log("response from server: ", data);
        setServerResponse(data);
        enqueueSnackbar("Brand added successfully.", { variant: "success" });
      }
    });
  };
  const loadBrands = async () => {
    try {
      const res = await getBrands();
      if (res.status === 200) setBrands(res.data);
    } catch (error) {
      //console.log("Error loading brands: ", error);
    }
  };

  useEffect(() => {
    loadBrands();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <AdminBaseLayout>
      <Box className="small-container" component={Paper} elevation={3}>
        <h2 className="title">Brands</h2>
        {showServerResponseMessage(serverResponse)}
        <Paper className="mgmt-container">
          <div className="mgmt-list-wrapper">
            <div className="table">
              <div className="table-header">
                <p>Brands:</p>
                <div className="th-actions">
                  <div className="search-bar">
                    <input type="text" placeholder="Brand" />
                    <span>
                      <i className="bx bx-search"></i>
                    </span>
                  </div>

                  <button
                    className="add-new"
                    onClick={() => navigate("/admin/brand")}
                  >
                    + Add New
                  </button>
                </div>
              </div>
              <div className="table-section">
                <table>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Created at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {brands &&
                      brands.map((brnd, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <img
                                src={`${LOCAL_API}/brand-image/${brnd.id}`}
                                alt=""
                              />
                            </td>
                            <td>{brnd.name}</td>
                            <td>{brnd.createdAt}</td>
                            <td>
                              <button>
                                <i className="bx bx-edit"></i>
                              </button>
                              <button>
                                <i className="bx bx-trash"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="mgmt-form-wrapper">
            <BrandForm
              brand={brand}
              setBrand={setBrand}
              submitForm={submitForm}
              btnName="Update"
            />
          </div>
        </Paper>
      </Box>
    </AdminBaseLayout>
  );
};

export default Brands;
