export const showServerResponseMessage = (responseMessage) => {
  let output = "";
  if (
    responseMessage &&
    responseMessage.status &&
    responseMessage.status === "error"
  ) {
    output = (
      <div
        className="alert alert-danger"
        style={{
          display:
            responseMessage.status && responseMessage.status === "error"
              ? "flex"
              : "none",
        }}
      >
        {responseMessage.message}
      </div>
    );
  } else if (
    responseMessage &&
    responseMessage.status &&
    responseMessage.status === "success"
  ) {
    output = (
      <div
        className="alert alert-success"
        style={{
          display:
            responseMessage.status && responseMessage.status === "success"
              ? "flex"
              : "none",
        }}
      >
        {responseMessage.message}
      </div>
    );
  } else {
    output = <div style={{ display: "none" }}>else</div>;
  }
  return output;
};
