import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Badge,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

import {
  ExitToApp,
  Favorite,
  Inventory,
  ShoppingCart,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { itemsTotal } from "src/helpers/cartHelpers";
import { AuthContext } from "src/contexts/AuthContextProvider";
import useRequestAuth from "src/hooks/useRequestAuth";

const drawerWidth = 240;
// const navItems = ["Home", "products", "About", "Contact"];
const navItemsObj = [
  { name: "Home", location: "/" },
  { name: "Products", location: "/products" },
  { name: "About us", location: "/" },
  { name: "Contact Us", location: "/" },
];

function AppHeader({ window }) {
  const { isAuthenticated, user } = React.useContext(AuthContext);
  const { logout } = useRequestAuth();
  const cartTotalItems = itemsTotal();

  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const MenuItems = document.getElementById("menu-items");

  MenuItems && (MenuItems.style.maxHeight = "0px");

  console.log("Is authenticated: ", isAuthenticated);
  console.log("User: ", user);
  console.log("cart items total: ", itemsTotal());

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", background: "#ff523b" }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        Duka La Jamii
      </Typography>
      <Divider />
      <List>
        {navItemsObj.map((item, idx) => (
          <ListItem key={idx} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                navigate(item.location);
                // handleDrawerToggle();
              }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      // anchorOrigin={{
      //   vertical: "top",
      //   horizontal: "right",
      // }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigate(`/user/profile/${user?.user?.id}`);
        }}
      >
        <Button variant="text" startIcon={<AccountCircle />} color="inherit">
          Profile
        </Button>
      </MenuItem>
      <MenuItem onClick={() => {}}>
        {" "}
        <Button
          variant="text"
          startIcon={<ExitToApp />}
          onClick={() => logout()}
          color="inherit"
        >
          Logout
        </Button>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = isAuthenticated && (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => navigate("/user/inbox")}>
        <IconButton
          size="large"
          aria-label="show new notifications"
          color="inherit"
        >
          <NotificationsIcon />
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={() => navigate("/user/orders")}>
        <IconButton size="large" aria-label="show new orders" color="inherit">
          <Inventory />
        </IconButton>
        <p>Orders</p>
      </MenuItem>
      <MenuItem onClick={() => navigate("/user/saved")}>
        <IconButton size="large" aria-label="show new orders" color="inherit">
          <Favorite />
        </IconButton>
        <p>Saved</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          // background: `radial-gradient(#fff, #ffd6d6)`,
          background: `#ffd6d6`,
          color: `#555`,
          mb: 1,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        xs={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box className="logo" sx={{ textAlign: "center" }}>
            <a href="/">
              <img
                src={process.env.PUBLIC_URL + "/images/logo_dark.png"}
                alt="logo"
                width="185px"
              />
            </a>
          </Box>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Duka La Jamii
          </Typography> */}

          <Box sx={{ flexGrow: 1 }}></Box>
          {/*Start of desktop menu for large screen display for logged in users */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Button
              variant="text"
              color="inherit"
              onClick={() => navigate("/")}
              fontSize="inherit"
              size="large"
            >
              Home
            </Button>
            <Button
              color="inherit"
              size="large"
              onClick={() => navigate("/products")}
            >
              Products
            </Button>
            <Button color="inherit" size="large" onClick={() => navigate("/")}>
              About
            </Button>
          </Box>
          <Box sx={{ display: "flex" }}>
            <IconButton
              aria-label="cart"
              size="medium"
              onClick={() => navigate("/cart")}
            >
              {cartTotalItems > 0 && (
                <Badge badgeContent={cartTotalItems} color="error">
                  <ShoppingCart fontSize="inherit" />
                </Badge>
              )}
              {(!cartTotalItems || cartTotalItems === 0) && (
                <ShoppingCart fontSize="inherit" />
              )}
            </IconButton>

            {!isAuthenticated && (
              <Box>
                <IconButton
                  aria-label="user"
                  onClick={() => navigate("/auth/account")}
                >
                  <AccountCircle />
                </IconButton>
              </Box>
              // <Button
              //   aria-label="sign in"
              //   onClick={() => navigate("/auth/account")}
              //   startIcon={<AccountCircle />}
              //   sx={{ color: "inherit" }}
              //   size="small"
              // >
              //   Account
              // </Button>
            )}
          </Box>
          {/* Icons for logged in user */}
          {isAuthenticated && (
            <Box>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          )}
          {/* End of dispay mobile toast menu on the top right side. */}
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "flex" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            background: `radial-gradient(#fff, #ffd6d6)`,
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}

export default AppHeader;
