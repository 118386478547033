import { useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import MultipleValueTextInput from "react-multivalue-text-input";
import { createProductType, getProductsAttributes } from "src/api/apiAdmin";
import { showServerResponseMessage } from "src/helpers/ShowMessageHelper";
import { isAuthenticated } from "src/services/auth";
import { AuthContext } from "src/contexts/AuthContextProvider";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";
import AdminBaseLayout from "src/components/Layouts/AdminBaseLayout/old-layout";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Product Type name is required")
    .max(100, "Max length is 100"),
});

const ProductTypeDetails = (props) => {
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [values, setValues] = useState({
    name: "",
    images: [],
    formData: new FormData(),
    new_attributes: [],
    selected_attributes: [],
  });
  const [productAttributes, setProductAttributes] = useState([]);

  const { user, token } = useContext(AuthContext);
  let { name, new_attributes, selected_attributes, images, formData } = values;

  const [serverResponse, setServerResponse] = useState({});

  useEffect(() => {
    getAllProductAttributes();
  }, []);
  const getAllProductAttributes = async () => {
    try {
      const productAttr = await getProductsAttributes();

      setProductAttributes(productAttr);
    } catch (error) {}
  };
  const handleChange = (fieldName) => (event) => {
    if (fieldName === "images") {
      //images = event.target.files[0];
      formData.append("images", event.target.files);
      //formData.set("file", event.target.files);
    } else if (fieldName === "attributes") {
      console.log("checkbox clicked: ", event.target.value);
      if (event.target.checked) {
        selected_attributes.push(event.target.value);
      } else {
        selected_attributes.splice(
          selected_attributes.indexOf(event.target.value),
          1
        );
      }
      selected_attributes.forEach((attr) => {
        formData.append("selected_attributes", attr);
      });
      console.log("Selected attributes variable: ", selected_attributes);
    } else {
      setValues({ ...values, [fieldName]: event.target.value });
      formData.set(fieldName, event.target.value);
      setServerResponse(null);
    }
  };
  const handleFileChange = (file) => {
    if (file.length) {
      for (let i = 0; i < file.length; i++) {
        images.push(file[i]);
        formData.append("images", file[i]);
      }
    }
    setValues({ ...values, images });
    //formData.set("images", images);
    // formData.set("files", files);
    console.log("images contains: ", images);
  };
  const onAttributeAdded = (item, allItems) => {
    new_attributes.push(item);
    setValues({ ...values, new_attributes });
    // formData.append("attributes", item);
    // console.log("added attribute: ", item);
    // console.log("attributes: ", new_attributes);
  };
  const onAttributeRemoved = (item, allItems) => {
    new_attributes.splice(new_attributes.indexOf(item), 1);
    setValues({ ...values, new_attributes });
    // formData.append("attributes", new_attributes);
    // console.log("Removed attribute: ", removed);
    // console.log("attributes left: ", new_attributes);
  };
  const handleSubmit = () => {
    console.log("product type form: ", values);
    let { formData, new_attributes, selected_attributes } = values;
    if (!formData) formData = new FormData();
    if (new_attributes && new_attributes.length) {
      for (let i = 0; i < new_attributes.length; i++) {
        formData.append("new_attributes", new_attributes[i]);
      }
    } else {
      formData.set("new_attributes", "");
    }
    if (selected_attributes && selected_attributes.length) {
      for (let i = 0; i < selected_attributes.length; i++) {
        formData.append("selected_attributes", selected_attributes[i]);
      }
    } else {
      formData.set("selected_attributes", "");
    }

    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }

    createProductType(formData, user.user, token)
      .then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: "error" });
        } else {
          console.log("Product type created. ", data);
          setValues({ name: "", images: "", formData: new FormData() });
          enqueueSnackbar("Product type created successfully", {
            variant: "success",
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar(e, { variant: "error" });
        console.log("Error creating product type. ", e);
      });
  };

  const showUploadImage = () =>
    images &&
    images.length > 0 && (
      <div className="row uploaded-images">
        {images.map((image, i) => (
          <div className="col" key={i}>
            <img src={URL.createObjectURL(image)} alt={image.name} />
          </div>
        ))}
      </div>
    );

  const addProductTypeForm = () => (
    <Box>
      <Formik
        onSubmit={handleSubmit}
        initialValues={values}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form
              className="product-type-form"
              onSubmit={formik.handleSubmit}
              // onSubmit={submitForm}
              encType="multipart/form-data"
            >
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="name"
                      sx={{
                        mt: 2,
                      }}
                      label="Product Type"
                      // {...formik.getFieldProps("name")}
                      value={values.name}
                      onChange={handleChange("name")}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {productAttributes.map((pAttr, idx) => {
                      return (
                        <FormControlLabel
                          key={idx}
                          control={
                            <Checkbox
                              value={pAttr.id}
                              onChange={handleChange("attributes")}
                            />
                          }
                          label={pAttr.name}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MultipleValueTextInput
                      onItemAdded={(item, allItems) => {
                        onAttributeAdded(item, allItems);
                      }}
                      onItemDeleted={(item, allItems) => {
                        onAttributeRemoved(item, allItems);
                      }}
                      name="attributes"
                      label="Product Type Attributes"
                      placeholder="Enter product type attributes; separate them with COMMA (,) or ENTER."
                      id="product-type-attributes"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FileUploader
                      handleChange={handleFileChange}
                      label="Add product type images"
                      name="images"
                      types={fileTypes}
                      multiple={false}
                    />
                  </Grid>
                </Grid>

                <input type="submit" className="btn" value="Add" />
              </Box>
            </form>
          );
        }}
      </Formik>
      <div>{showUploadImage()}</div>
    </Box>
  );
  return (
    <AdminBaseLayout>
      <Box component={Paper} elevation={3} className="small-container">
        <Box className="row">
          <Box className="col-2">
            <h2 className="title">Add Product Type</h2>
            {showServerResponseMessage(serverResponse)}
            {addProductTypeForm()}
          </Box>
        </Box>
      </Box>
    </AdminBaseLayout>
  );
};
export default ProductTypeDetails;
