import axios from "axios";
import { LOCAL_API } from "../../config";
import TokenService from "./TokenService";

export const signUp = (user) => {
  return axios.post(`${LOCAL_API}/auth/sign-up`, user, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
export const signIn = (user) => {
  //console.log(JSON.stringify(user) );
  return axios.post(`${LOCAL_API}/auth/sign-in`, user, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};
export const signOut = (next) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("jwt");
    next();
    return axios
      .get(`${LOCAL_API}/auth/sign-out`)
      .then((response) => {
        console.log("signout", response);
        TokenService.removeJWT();
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const isAuthenticated = () => {
  if (typeof window == "undefined") return false;
  if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};
