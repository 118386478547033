import axios from "axios";
import { LOCAL_API } from "../config";
import getCommonOptions from "src/helpers/axios/getCommonOptions";
export const createProduct = async (product, user) => {
  return axios.post(
    `${LOCAL_API}/admin/product/add/${user.id}`,
    product,
    getCommonOptions("multipart/form-data")
  );
};
export const createProductWithInventory = (productWithInventory, user) => {
  console.log(
    "createProductwithInventory inside productAPI, getting some error. "
  );
  console.log("Data being posted is: ", productWithInventory);
  return axios.post(
    `${LOCAL_API}/admin/product/add-with-inventory/${user.id}`,
    productWithInventory,
    getCommonOptions("multipart/form-data")
  );
};
export const updateProduct = (productId, userId, token, product) => {
  console.log("updateProduct productId and product form", productId, product);
  return axios.put(
    `${LOCAL_API}/admin/product/${productId}/${userId}`,
    product,
    getCommonOptions("multipart/form-data")
  );
};

export const getProducts = () => {
  return axios.get(`${LOCAL_API}/products`);
};
export const getFilteredProducts = (page, limit, filters = {}) => {
  return axios.get(`${LOCAL_API}/products?page=${page}&limit=${limit}`);
};
export const getProductById = (productId) => {
  return axios.get(`${LOCAL_API}/product/${productId}`);
};

export const getProductInventoryCount = (productId) => {
  return axios.get(`${LOCAL_API}/product/${productId}/product-inventory-count`);
};

export const searchProduct = (productName) => {
  return axios.get(`${LOCAL_API}/products/search?q=${productName}&limit=10`);
};

export const getProductCategories = (web_id) => {
  return axios.get(`${LOCAL_API}/product/categories/${web_id}`);
};

export const deleteProduct = (productId, userId, token) => {
  return axios.delete(
    `${LOCAL_API}/product/${productId}/${userId}`,
    getCommonOptions()
  );
};
export const deleteAProduct = (web_id, userId, token) => {
  return axios.delete(
    `${LOCAL_API}/product/remove/${web_id}/${userId}`,
    getCommonOptions()
  );
};
