import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getProductTypeAttributes } from "src/api/productTypeAPI";
const ProductAttributesForm = React.forwardRef(
  ({ productTypeId, attributes, setAttributes }, ref) => {
    const [productTypeAttr, setProductTypeAttr] = useState([]);
    const handleChange = (name) => (event) => {
      console.log("An input changed in ProductAttributesForm");
      console.log(event.target.name + ": " + event.target.value);
      setAttributes({ ...attributes, [name]: event.target.value });
    };
    useEffect(() => {
      console.log("Product type ID is: ", productTypeId);
      const init = async () => {
        try {
          const res = await getProductTypeAttributes(productTypeId);
          console.log("Product type attributes: ", res?.data);
          if (res.status === 200) {
            setProductTypeAttr(res.data);
          }
        } catch (error) {}
      };
      init();
    }, [productTypeId]);
    return (
      <div className="tab" ref={ref}>
        <h2 className="title">Product attributes</h2>
        <form id="productAttributesForm">
          <Grid container>
            {productTypeAttr &&
              productTypeAttr.map((attribute, idx) => {
                return (
                  <Grid item xs={12} key={idx}>
                    <TextField
                      fullWidth
                      id={`i-${idx}`}
                      // value={attributes[`${attribute.productAttribute.id}`]}
                      label={attribute.productAttribute.name}
                      type="text"
                      onChange={handleChange(attribute.productAttribute.id)}
                      className="form-control"
                      aria-describedby="nameHelp"
                    />
                  </Grid>
                );
              })}
          </Grid>
        </form>
      </div>
    );
  }
);
export default ProductAttributesForm;
