import { Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAttributesForProductInventory } from "src/api/productAttributesAPI";
import {
  getProductInventoryById,
  getProductInventoryImages,
} from "src/api/productInventoryAPI";
import Card from "src/components/reusable/Card";
import { LOCAL_API } from "src/config";
import { ShoppingCartContext } from "src/contexts/ShoppingCartContextProvider";

const Product = (props) => {
  const { addCartItem, updateCartItem } = useContext(ShoppingCartContext);
  const [product, setProduct] = useState({});
  const [productImages, setProductImages] = useState([{}]);
  const [productAttributes, setProductAttributes] = useState([{}]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [count, setCount] = useState(1);

  const params = useParams();

  const mainImage = useRef();
  const imageSlides = useRef([]);
  imageSlides.current = [];

  const addRefToSlides = (el) => {
    if (el && !imageSlides.current.includes(el)) {
      imageSlides.current.push(el);
    }
  };
  const changeMainImage = (event) => {
    mainImage.current.src = event.target.src;
  };
  const addToCart = () => {
    const quantity = document.querySelector("#quantity");
    if (quantity.value >= 1) {
      product.count = quantity.value;
    }
    addCartItem(product, () =>
      enqueueSnackbar(`${product.product.name} added to cart`, {
        variant: {
          color: "success",
        },
      })
    );
  };
  const handleChange = (event, productId) => {
    setCount(event.target.value < 1 ? 1 : event.target.value);
    if (event.target.value >= 1) {
      updateCartItem(productId, event.target.value);
    }
  };

  useEffect(() => {
    const loadSingleProduct = async (productInventoryId) => {
      try {
        const res = await getProductInventoryById(productInventoryId);
        setProduct(res.data);
        const resImages = await getProductInventoryImages(productInventoryId);
        console.log("Product images:", resImages);
        setProductImages(resImages.data);
        const resAttributes = await getAttributesForProductInventory(
          productInventoryId
        );
        setProductAttributes(resAttributes.data);
        //fetch related products
      } catch (error) {
        // console.log("Error loading single product inventory");
      }
    };

    const productId = params.productId;
    loadSingleProduct(productId);
  }, [params]);

  return (
    <>
      <div className="small-container single-product">
        <div className="row">
          <div className="col-2 product-images">
            <img
              src={`${LOCAL_API}/product/image/${product?.id}`}
              alt=""
              width="100%"
              maxheight="400px"
              id="product-img"
              ref={mainImage}
            />

            <div className="small-img-row">
              {productImages &&
                productImages?.map((image, idx) => {
                  return (
                    <div className="small-img-col" key={idx}>
                      <img
                        src={`${LOCAL_API}/product-images/image/${image?.id}`}
                        alt={image.name}
                        ref={addRefToSlides}
                        onClick={(e) => {
                          mainImage.current.src = e.target.src;
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="col-2">
            {/* <p>Home/{product.category && product.category.name} </p> */}
            <h2>{product?.product?.name}</h2>
            <h4>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "KSh",
              }).format(product?.storePrice)}
            </h4>

            <input
              type="number"
              id="quantity"
              defaultValue={count}
              onChange={(event) => handleChange(event)}
            />
            <Link to="#" className="btn" onClick={addToCart}>
              {" "}
              Add to cart
            </Link>
            <h3>
              Product Details <i className="fa fa-indent"></i>
            </h3>
            <br />
            {productAttributes && productAttributes.length > 0 && (
              <table className="productDetails">
                {/* <thead>
                  <tr>
                    <th>Name</th>
                    <th>value</th>
                  </tr>
                </thead> */}
                <tbody>
                  {productAttributes.map((prodAttrs, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          {
                            prodAttrs.productAttributeValue?.productAttribute
                              .name
                          }
                        </td>
                        <td>
                          {prodAttrs.productAttributeValue?.attributeValue}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <Stack>
            <h2 className="title">Description</h2>
            <p>{product?.product?.description}</p>
          </Stack>
        </div>

        <div className="small-container">
          <div className="row row-2">
            <h2>Related products</h2>
            <p>View more</p>
          </div>
        </div>

        <div className="row related-products">
          {relatedProducts.map((data, index) => (
            <div className="col-4" key={index}>
              <Card product={data} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Product;
